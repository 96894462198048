import * as React from 'react';
import { Box, TextField, Button, Typography, Avatar, Grid, Link } from '@mui/material';
import Container from '@mui/material/Container';
import mobile from 'is-mobile';
import axios from 'axios';
import LockIcon from '@mui/icons-material/Lock';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

import Copyright from '../components/copyright';

export default function Register(props) {
    let history = useHistory();

    const [isSending, toggleSending] = React.useState(false);
    const [validators, setValidations] = React.useState({email: false, password: false, passwordConfirm: false, phoneNumber: false, firstName: false, lastName: false});

    const validateEmail = (email) => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setValidations({...validators, email: !emailRegex.test(String(email).toLowerCase())});
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        setValidations({...validators, phoneNumber: !phoneRegex.test(String(phone).toLowerCase())});
    };

    const validateName = (propertyName, nameValue) => {
        setValidations({...validators, [propertyName]: String(nameValue).length < 1});
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        setValidations({...validators, password: !passwordRegex.test(String(password))});
    };

    const validatePasswordConfirm = (passwordConfirm) => {
        setValidations({...validators, passwordConfirm: document.getElementById('password').value !== passwordConfirm});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (validators.email || validators.password || validators.passwordConfirm || validators.firstName || validators.lastName || validators.phoneNumber)
        {
            props.setSnackbar({message: "The form is not properly filled out. Please check input for errors and try again.", severity: "error", open: true });
            return;
        }

        props.setBackdrop(true);
        toggleSending(true);

        // eslint-disable-next-line no-console
        axios.post(`${process.env.REACT_APP_API_URI}/Authentication/register`, {
            email: data.get('email'),
            password: data.get('password'),
            phoneNumber: data.get('phoneNumber').replace(/\D/g, ""),
            firstName: data.get('firstName'),
            lastName: data.get('lastName'),
        })
        .then((response) => {
            // Snackbar success
            props.setSnackbar({message: "Account created! You will now be navigated to sign in.", severity: "success", open: true });
            // Add interval until redirect to sign-in
            history.push('/sign-in')
        })
        .catch((error) => {
            props.setSnackbar({message: "We ran into an error registering account.", severity: "error", open: true });
        })
        .finally((tings) =>
        {
            // End the loading screen
            props.setBackdrop(false);

            // Enable Button
            toggleSending(false);
        });
  };

  return (
        <Box
            sx={{
                marginTop: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                height: '100%',
                width: '100vw',
            }}
            component="form"
            onSubmit={handleSubmit}
        >
            <Container maxWidth="sm" sx={{ alignItems: 'center', display: 'block'}}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main', display: 'inline-flex' }}>
                    <img src="/EF_Icon.png" alt="EagleFoundryIcon" width="300%" />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Register
                </Typography>
                <div style={{display: 'block', marginTop: '1em'}}>
                    <TextField
                        sx={{ width: mobile() ? "225px" : "260px", marginTop: '1em'}}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        error={validators.email}
                        onBlur={(e) => validateEmail(e.target.value)}
                        helperText={ validators.email && "Email address is invalid."}
                    />
                    <div style={{display: 'block'}}>
                        <TextField
                            sx={{ width: mobile() ? "225px" : "260px", marginTop: '1em'}}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="New Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            error={validators.password}
                            onBlur={(e) => { validatePassword(e.target.value) }}
                            helperText="Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
                        />
                    </div>
                    <div style={{display: 'block'}}>
                        <TextField
                            sx={{ width: mobile() ? "225px" : "260px", marginTop: '1em'}}
                            margin="normal"
                            required
                            fullWidth
                            name="passwordConfirm"
                            label="Re-Enter Password"
                            type="password"
                            id="passwordConfirm"
                            autoComplete="current-password-confirm"
                            error={validators.passwordConfirm}
                            onBlur={(e) => { validatePasswordConfirm(e.target.value) }}
                            helperText={ validators.passwordConfirm && "Passwords do not match."}
                        />
                    </div>
                    <div style={{display: 'block'}}>
                        <TextField
                            sx={{ width: mobile() ? "225px" : "260px", marginTop: '1em'}}
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            autoComplete="first-name"
                            error={validators.firstName}
                            onBlur={(e) => {validateName('firstName', e.target.value)}}
                            helperText={ validators.firstName && "First name is required."}
                        />
                    </div>
                    <div style={{display: 'block'}}>
                        <TextField
                            sx={{ width: mobile() ? "225px" : "260px", marginTop: '1em'}}
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoComplete="last-name"
                            error={validators.lastName}
                            onBlur={(e) => {validateName('lastName', e.target.value)}}
                            helperText={ validators.lastName && "Last Name is required."}
                        />
                    </div>
                    <div style={{display: 'block'}}>
                        <InputMask
                            mask="(999) 999-9999"
                            disabled={false}
                            maskChar=" "
                            onBlur={(e) => validatePhone(e.target.value)}
                        >
                            {() => <TextField
                                    sx={{ width: mobile() ? "225px" : "260px", marginTop: '1em'}}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="phoneNumber"
                                    label="Phone Number"
                                    name="phoneNumber"
                                    autoComplete="phone-number"
                                    error={validators.phoneNumber}
                                    helperText={ validators.phoneNumber && "Phone Number is required."}
                                />
                            }
                        </InputMask>
                    </div>
                    <Typography sx={{width: mobile() ? "225px" : "260px", display: 'block', margin: "0 auto", marginTop: '2em'}} variant="caption"><LockIcon color="primary" />These accounts are for employee access only.</Typography>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ width: mobile() ? "225px" : "260px", marginTop: '1em'}}
                        disabled={isSending}
                    >
                        REGISTER ACCOUNT
                    </Button>
                    <Grid sx={{ mt: '1em', textAlign: 'center'}} container>
                        <Grid item xs>
                            <Link style={{cursor: 'pointer'}} onClick={() => history.push('/sign-in')} color='secondary' variant="body2">
                                Click here to go back to Sign in.
                            </Link>
                        </Grid>
                    </Grid>
                </div>
                <Copyright sx={{ mt: 8, mb: 4}} />
            </Container>
        </Box>
        
  );
}