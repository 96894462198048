import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Backdrop, CircularProgress } from '@mui/material';

import ButtonAppBar from '../components/mainAppBar';
import Snackybar from '../components/snackybar';
import TrackOrder from '../pages/trackOrder';
import Home from '../pages/home';
import Dashboard from '../pages/dashboard';
import Search from '../pages/search';
import BottomNav from '../components/bottomNav';
import Order from '../pages/order';
import OrderDetails from '../pages/orderDetails';
import TrackOrderDetails from '../pages/trackOrderDetails';
import Customer from '../pages/customer';
import CustomerDetails from '../pages/customerDetails';
import SignIn from '../pages/signIn';
import SignOut from '../pages/signout';
import Register from '../pages/register';
import ForgotPassword from '../pages/forgotPassword';
import ResetPassword from '../pages/resetPassword';

const theme = createTheme({
  components: {
      MuiPaper: {
          styleOverrides: {
              root: {
                  backgroundColor: 'lightgrey'
              }
          }
      }
  },
  palette: {
      primary: {
          main: "#1E5F74"
      },
      secondary: {
          main: "#1F4068"
      }
  }
});

function AppLayout() {
  const [snackBar, setSnackbar] = useState({message: '', severity: '', open: false});
  const [backdrop, setBackdrop] = useState(false);

  const isLoggedIn = () => {
    let result = document.cookie.split('; ').find(row => row.startsWith('AuthJWT=')) !== null && document.cookie.split('; ').find(row => row.startsWith('AuthJWT=')) !== undefined && !document.cookie.split('; ').find(row => row.startsWith('AuthJWT=')).includes("undefined");
    return result;
  };

  return (
      <div className="AppLayout">
        <header className="AppLayout-container">
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <ButtonAppBar isLoggedIn={isLoggedIn} />
              <Switch>
                <Redirect exact from="/" to='/home' />
                <Route path='/home'>
                  <Home />
                </Route>
                <Route path='/sign-in'>
                    <SignIn setBackdrop={setBackdrop} setSnackbar={setSnackbar} />
                </Route>
                <Route path='/sign-out'>
                  { !isLoggedIn() ? 
                    <Redirect to={`/sign-in?redirect=true&redirectUrl=${window.location.href}`} />
                      :
                    <SignOut setBackdrop={setBackdrop} />
                  }
                </Route>
                <Route path='/register'>
                    <Register setBackdrop={setBackdrop} setSnackbar={setSnackbar} />
                </Route>
                <Route path='/forgot-password'>
                    <ForgotPassword setBackdrop={setBackdrop} setSnackbar={setSnackbar} />
                </Route>
                <Route path='/reset-password'>
                    <ResetPassword setBackdrop={setBackdrop} setSnackbar={setSnackbar} />
                </Route>
                <Route path='/track-order'>
                  <TrackOrder setSnackbar={setSnackbar} />
                </Route>
                <Route path='/track-order-details'>
                  <TrackOrderDetails setBackdrop={setBackdrop} setSnackbar={setSnackbar} />
                </Route>  
                <div>
                  <Route path='/new-customer'>
                    { !isLoggedIn() ? 
                      <Redirect to={`/sign-in?redirect=true&redirectUrl=${window.location.href}`} />
                      :
                      <Customer setBackdrop={setBackdrop} setSnackbar={setSnackbar} />
                    }
                  </Route>
                  <Route path='/customer-details/:id'>
                    { !isLoggedIn() ? 
                      <Redirect to={`/sign-in?redirect=true&redirectUrl=${window.location.href}`} />
                      :
                      <CustomerDetails setBackdrop={setBackdrop} setSnackbar={setSnackbar} />
                    }
                  </Route>
                  <Route path='/new-order'>
                    { !isLoggedIn() ? 
                      <Redirect to={`/sign-in?redirect=true&redirectUrl=${window.location.href}`} />
                      :
                      <Order setBackdrop={setBackdrop} setSnackbar={setSnackbar} />
                    }
                  </Route>
                  <Route path='/order-details/:id'>
                    { !isLoggedIn() ? 
                      <Redirect to={`/sign-in?redirect=true&redirectUrl=${window.location.href}`} />
                      :
                      <OrderDetails setBackdrop={setBackdrop} setSnackbar={setSnackbar} />
                    }
                  </Route>
                  <Route path='/search'>
                    { !isLoggedIn() ? 
                      <Redirect to={`/sign-in?redirect=true&redirectUrl=${window.location.href}`} />
                      :
                      <Search setBackdrop={setBackdrop} setSnackbar={setSnackbar} />
                    }
                  </Route>
                  <Route path='/dashboard'>
                    { !isLoggedIn() ? 
                      <Redirect to={`/sign-in?redirect=true&redirectUrl=${window.location.href}`} />
                      :
                      <Dashboard setBackdrop={setBackdrop} setSnackbar={setSnackbar} />
                    }
                  </Route>
                  <BottomNav />
                </div>
              </Switch>
            </Router>
            <Snackybar snackbar={snackBar} setSnackbar={setSnackbar} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdrop}
            >
                <CircularProgress color="primary" />
            </Backdrop>
          </ThemeProvider>
        </header>
      </div>
  );
}

export default AppLayout;