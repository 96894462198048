import React from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';

import * as constants from '../common/constants';

const useStyles = makeStyles((theme) => ({
    homeContainer: {
        marginTop: '1em',
        padding: '10px'
    },
    gridElements: {
        marginBottom: '.5em'
    },
    textField: {
        width: '200px'
    },
    paperContainer: {
        padding: '5px'
    }
  }));

  function TrackOrderDetails(props) {
    const classes = useStyles();
    const history = useHistory();
    const search = useLocation().search;

    const [status, setStatus] = React.useState(false);
    const [name, setName] = React.useState("");
    
    React.useEffect(() => {
        const queryParams = new URLSearchParams(search)
    
        props.setBackdrop(true);

        setName(`${queryParams.get('f')} ${queryParams.get('l')}`);

        axios.post(`${process.env.REACT_APP_API_URI}/order/search`, {
            firstName: queryParams.get('f'),
            lastName: queryParams.get('l'),
            dateOfBirth: new Date(queryParams.get('d'))
          })
          .then((response) => {
            setStatus(response.data);

          })
          .catch((error) => {
            props.setSnackbar({message: "Error retreiving data, please try again later.", severity: "error", open: true });
          })
          .finally((tings) =>
          {
              // End the loading screen
              props.setBackdrop(false);
      
          });
        
          queryParams.delete('f');
          queryParams.delete('l');
          queryParams.delete('d');
          history.replace({
            search: queryParams.toString(),
          });
      }, []);

      const getText = () => {
        switch(status) {
            case 'received':
                return `The order for ${name} has been received.`;
            case 'processing':
                return `The order for ${name} is currently processing. Once it is shipped, expect an email containing the tracking number from ${constants.EagleFoundryNoReply}.`;
            case 'cancelled':
                return `The order for ${name} was cancelled. If you feel this was in error, please reach out to us at: ${constants.EagleFoundryPhone}.`;
            case 'shipped':
                return `The order for ${name} has been shipped! An email was sent to the customer email we had on file from: ${constants.EagleFoundryNoReply}.`;
            case 'waitingForPayment':
                return `The order for ${name} is currently awaiting payment. Please contact us at: ${constants.EagleFoundryPhone}.`;
            default:
                return `The order with the information you have entered could not be found. Please double check your info and try again. If the problem persists, you can reach us at: ${constants.EagleFoundryPhone}.`;
        }
      };

    return (
        <div className={classes.homeContainer}>
            <Paper className={classes.paperContainer}>
                <Grid container spacing={2}>
                    <Grid xs={12} item className={classes.gridElements}>
                        <Typography variant='h4' >Order Status</Typography>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <Typography variant='h6' >{getText()}</Typography>
                        <hr />
                        { status === "cancelled" || status === "processing" || status === "received" || status === "shipped" ?
                            <Typography variant='h6' >Questions? You can reach us at: {constants.EagleFoundryPhone}.</Typography>
                            :
                            <div></div>
                        }
                    </Grid>
                    <Grid xs={12} item>
                        <Button 
                            color='secondary' 
                            size='large' 
                            variant='contained'
                            className={classes.textField}
                            onClick={() => { history.push('/track-order') } }
                        >
                            Find Another Order
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
  }
  
  export default TrackOrderDetails;