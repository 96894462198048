import * as React from 'react';
import { Box, TextField, Button, Typography, Avatar, Grid, Link } from '@mui/material';
import Container from '@mui/material/Container';
import mobile from 'is-mobile';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';

import Copyright from '../components/copyright';

export default function ResetPassword(props) {
    let history = useHistory();
    const search = useLocation().search;

    const [isSending, toggleSending] = React.useState(false);
    const [validators, setValidations] = React.useState({password: false, passwordConfirm: false});

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        setValidations({...validators, password: !passwordRegex.test(String(password))});
    };

    const validatePasswordConfirm = (passwordConfirm) => {
        setValidations({...validators, passwordConfirm: document.getElementById('password').value !== passwordConfirm});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (validators.password || validators.passwordConfirm)
        {
            props.setSnackbar({message: "The form is not properly filled out. Please check input for errors and try again.", severity: "error", open: true });
            return;
        }

        props.setBackdrop(true);
        toggleSending(true);

        axios.post(`${process.env.REACT_APP_API_URI}/Authentication/resetPassword`, {
            email: new URLSearchParams(search).get('email'),
            token: new URLSearchParams(search).get('token'),
            password: data.get('password')
        })
        .then((response) => {
            // Snackbar success
            props.setSnackbar({message: "Password reset! You will now be navigated to sign in.", severity: "success", open: true });
            // Add interval until redirect to sign-in
            history.push('/sign-in');
        })
        .catch((error) => {
            props.setSnackbar({message: "We ran into an error resetting password.", severity: "error", open: true });
        })
        .finally((tings) =>
        {
            // End the loading screen
            props.setBackdrop(false);

            // Enable Button
            toggleSending(false);
        });
  };

  return (
        <Box
            sx={{
                marginTop: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                height: '100%',
                width: '100vw',
            }}
            component="form"
            onSubmit={handleSubmit}
        >
            <Container maxWidth="sm" sx={{ alignItems: 'center', display: 'block'}}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main', display: 'inline-flex' }}>
                    <img src="/EF_Icon.png" alt="EagleFoundryIcon" width="300%" />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                <div style={{display: 'block', marginTop: '1em'}}>
                    <div style={{display: 'block'}}>
                        <TextField
                            sx={{ width: mobile() ? "225px" : "260px", marginTop: '1em'}}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="New Password"
                            type="password"
                            id="password"
                            autoComplete="password"
                            error={validators.password}
                            onBlur={(e) => { validatePassword(e.target.value) }}
                            helperText={"Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."}
                        />
                    </div>
                    <div style={{display: 'block'}}>
                        <TextField
                            sx={{ width: mobile() ? "225px" : "260px", marginTop: '1em'}}
                            margin="normal"
                            required
                            fullWidth
                            name="passwordConfirm"
                            label="Re-Enter Password"
                            type="password"
                            id="passwordConfirm"
                            autoComplete="passwordConfirm"
                            error={validators.passwordConfirm}
                            onBlur={(e) => { validatePasswordConfirm(e.target.value) }}
                            helperText={ validators.passwordConfirm && "Passwords do not match."}
                        />
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ width: mobile() ? "225px" : "260px", marginTop: '1em'}}
                        disabled={isSending}
                    >
                        Reset Password
                    </Button>
                    <Grid sx={{ m: 'auto', textAlign: 'center'}} container>
                        <Grid item xs>
                            <Link style={{cursor: 'pointer'}} onClick={() => history.push('/sign-in')} color='secondary' variant="body2">
                                Click here to go back to Sign in.
                            </Link>
                        </Grid>
                    </Grid>
                </div>
                <Copyright sx={{ mt: 8, mb: 4}} />
            </Container>
        </Box>
        
  );
}