import 'date-fns';
import React from 'react';
import { Button, Grid, Paper, TextField, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    homeContainer: {
        marginTop: '1em',
        padding: '10px'
    },
    gridElements: {
        marginBottom: '.5em'
    },
    textField: {
        width: '200px'
    },
    paperContainer: {
        padding: '5px'
    }
  }));

  function TrackOrder(props) {
    const classes = useStyles();
    const history = useHistory();

    const [firstName, setFirstName] = React.useState();    
    const [lastName, setLastName] = React.useState();    
    const [dateOfBirth, setDOB] = React.useState(null);
    const [validators, setValidations] = React.useState({firstName: false, lastName: false, dateOfBirth: false});    

    const handleDOBChange = (date) => {
        setDOB(date);
    };

    const validateFirstName = (firstName) => {
        setValidations({...validators, firstName: firstName.length <= 2});
    };

    const validateLastName = (lastName) => {
        setValidations({...validators, lastName: lastName.length <= 2});
    };

    const validateBirthDate = (dateOfBirth) => {
        setValidations({...validators, dateOfBirth: dateOfBirth === null || dateOfBirth === ""});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (validators.firstName === true || validators.lastName === true || validators.dateOfBirth === true)
        {
            props.setSnackbar({message: "The form is not properly filled out. Please check input for errors and try again.", severity: "error", open: true });
            return;
        }
        
        const data = new FormData(event.currentTarget);
        
        history.push(`/track-order-details?f=${data.get('firstName')}&l=${data.get('lastName')}&d=${data.get('dateOfBirth')}`);
      };

    return (
        <Box 
            className={classes.homeContainer}
            component="form"
            onSubmit={handleSubmit}    
        >
            <Paper className={classes.paperContainer}>
                <Grid container spacing={2}>
                    <Grid xs={12} item className={classes.gridElements}>
                        <Typography variant='h4' >Find Order Status</Typography>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <Typography variant='h6' >Please provide the following details of the marker to look up an order's status.</Typography>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <TextField
                            className={classes.textField}
                            id='firstName-input'
                            label='First Name'
                            name='firstName'
                            required
                            onChange={(ele) => { setFirstName(ele.currentTarget.value) }}
                            value={firstName}
                            error={validators.firstName}
                            onBlur={(e) => validateFirstName(e.target.value)}
                            helperText={ validators.firstName && "Name must be longer than 2 characters."}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <TextField
                            className={classes.textField}
                            id='lastName-input'
                            label='Last Name'
                            name='lastName'
                            required
                            onChange={(ele) => { setLastName(ele.currentTarget.value) }}
                            value={lastName}
                            error={validators.lastName}
                            onBlur={(e) => validateLastName(e.target.value)}
                            helperText={ validators.lastName && "Name must be longer than 2 characters."}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                required
                                className={classes.textField}
                                label="Marker Date of Birth"
                                id="dob-input"
                                value={dateOfBirth}
                                onChange={handleDOBChange}
                                maxDate={Date.now()}
                                renderInput={(params) => <TextField {...params} name='dateOfBirth' onBlur={(e) => validateBirthDate(e.target.value)} required error={validators.dateOfBirth} helperText={validators.dateOfBirth && "Birthdate is required."} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} item>
                        <Button 
                            color='secondary' 
                            size='large' 
                            variant='contained'
                            className={classes.textField}
                            type="submit"
                        >
                            Find Order Status
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
  }
  
  export default TrackOrder;