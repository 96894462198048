import 'date-fns';
import React from 'react';
import { Checkbox, FormControlLabel, FormHelperText, Grid, Paper, Typography, TextField, TextareaAutosize, FormControl, FormLabel, Button, InputLabel, MenuItem, Select, Box, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ImageUploader from 'react-images-upload';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

import SelectWithAdd from '../components/selectWithAdd';
import StateTextBox from '../components/stateTextBox';
import NumberTextMask from '../components/numberTextMask';
import { GetHeaders } from '../utils/utils';
import OrderQRCode from '../components/orderQRCode';
import Carousel from '../components/carousel';

const useStyles = makeStyles((theme) => ({
    dashboardContainer: {
        marginTop: '1em',
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20
    },
    gridElements: {
        marginBottom: '.5em'
    },
    textField: {
        width: 250,
        maxWidth: 250,
        minWidth: 200
    },
    paperContainer: {
        padding: 5
    },
    label: {
        textAlign: 'left'
    }
  }));

  function OrderDetails(props) {
    const { id } = useParams();
    const history = useHistory();

    const classes = useStyles();
    const [edit, toggleEdit] = React.useState(false);
    const [orderId, setOrderId] = React.useState();
    const [customer, setCustomer] = React.useState(" ");
    const [companyName, setCompanyName] = React.useState(" ");
    const [firstName, setFirstName] = React.useState();
    const [middleName, setMiddleName] = React.useState();
    const [lastName, setLastName] = React.useState();    
    const [dateOfBirth, setDOB] = React.useState(null);    
    const [dateOfDeath, setDOD] = React.useState(null);
    const [inscription, setInscription] = React.useState();
    const [markerSize, setMarkerSize] = React.useState();
    const [description, setDescription] = React.useState("");
    const [productNumber, setProductNumber] = React.useState();
    const [color, setColor] = React.useState("");
    const [vase, setVase] = React.useState(false);
    const [vaseWithRing, setVaseWithRing] = React.useState(false);
    const [hasProof, setHasProof] = React.useState(false);
    const [orderStatus, setOrderStatus] = React.useState(1);
    const [shippingAddressId, setShippingAddressId] = React.useState();
    const [estimateAmount, setEstimateAmount] = React.useState();
    const [notes, setNotes] = React.useState();
    const [images, setImages] = React.useState([]);
    const [imageUris, setImageUris] = React.useState([]);
    const [trackingNumber, setTrackingNumber] = React.useState();
    const [trackingType, setTrackingType] = React.useState(1);
    const [useCustomerAddress, setUseCustomerAddress] = React.useState(false);
    const [shippingContact, setShippingContact] = React.useState();
    const [street1, setStreet1] = React.useState();    
    const [street2, setStreet2] = React.useState();       
    const [city, setCity] = React.useState();    
    const [state, setState] = React.useState(' ');
    const [zip, setZip] = React.useState(' ');
    const [customerList, setCustomerList] = React.useState([]);
    const [isSending, toggleSending] = React.useState(false);
    const [validators, setValidations] = React.useState({customer: false, firstName: false, lastName: false, dateOfBirth: false, markerSize: false, productNumber: false, street1: false, city: false, state: false, zip: false});

    React.useEffect(() => {
        props.setBackdrop(true);

        // Gets the schedule services availability
        axios.get(`${process.env.REACT_APP_API_URI}/Customer/list`, GetHeaders())
        .then((response) => {
            setCustomerList(response.data);
        })
        .catch((error) => {
            props.setSnackbar({message: 'Error loading customer list.', severity: 'error', open: true});
        })
        .finally((tings) => {
            // End the loading screen
            props.setBackdrop(false);
        });

        axios.get(`${process.env.REACT_APP_API_URI}/order/${id}`, GetHeaders())
            .then((response) => {
                setOrderId(response.data.id);
                setCustomer(response.data.customerId);
                setCompanyName(response.data.customer.companyName);
                setFirstName(response.data.markerFirstName);
                setMiddleName(response.data.markerMiddleName);
                setLastName(response.data.markerLastName);
                setDOB(new Date(response.data.markerDateOfBirth));
                setDOD(response.data.markerDateOfDeath !== null ? new Date(response.data.markerDateOfDeath) : null);
                setInscription(response.data.inscription);
                setOrderStatus(response.data.status);
                setShippingAddressId(response.data.isCustomerAddress ? null : response.data.shippingAddress.id);
                setStreet1(response.data.shippingAddress.addressLine1);
                setStreet2(response.data.shippingAddress.addressLine2);
                setCity(response.data.shippingAddress.city);
                setState(response.data.shippingAddress.state);
                setZip(response.data.shippingAddress.zip);
                setMarkerSize(response.data.markerSize);
                setProductNumber(response.data.productNumber);
                setEstimateAmount(response.data.estimateAmount);
                setNotes(response.data.notes);
                setDescription(response.data.description);
                setColor(response.data.color);
                setVase(response.data.vase);
                setVaseWithRing(response.data.vaseWithRing);
                setHasProof(response.data.hasProof);
                setImageUris(response.data.images);
                setShippingContact(response.data.shippingContact);
                setUseCustomerAddress(response.data.isCustomerAddress);
                setTrackingNumber(response.data.trackingNumber);
                setTrackingType(response.data.trackingType ? response.data.trackingType : 1);
            })
            .catch((error) => {
                props.setSnackbar({message: "Error loading customer data.", severity: 'error', open: true});
            })
            .finally((tings) => {
                // End the loading screen
                props.setBackdrop(false);
            });

    }, []);

    const handleDOBChange = (date) => {
        setDOB(date);
    };

    const handleDODChange = (date) => {
        setDOD(date);
    };

    const onDrop = (pictureFiles, pictureDataURLs) => {
        setImages(images.concat(pictureFiles));
    };

    const validateCustomer = (customer) => {
        setValidations({...validators, customer: customer <= 0 || customer === null || customer === "" || customer === undefined });
    };

    const validateFirstName = (firstName) => {
        setValidations({...validators, firstName: firstName.length <= 2});
    };

    const validateLastName = (lastName) => {
        setValidations({...validators, lastName: lastName.length <= 2});
    };

    const validateBirthDate = (dateOfBirth) => {
        setValidations({...validators, dateOfBirth: dateOfBirth === null || dateOfBirth === ""});
    };

    const validateMarkerSize = (markerSize) => {
        setValidations({...validators, markerSize: markerSize.length <= 0});
    };

    const validateProductNumber = (productNumber) => {
        setValidations({...validators, productNumber: productNumber.length <= 0});
    };

    const validateStreet1 = (street1) => {
        setValidations({...validators, street1: street1.length <= 2});
    };

    const validateCity = (city) => {
        setValidations({...validators, city: city.length < 2});
    };

    const validateState = (state) => {
        setValidations({...validators, state: state.length < 2});
    };

    const validateZip = (zip) => {
        setValidations({...validators, zip: zip.length < 5});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (validators.customer === true || validators.firstName === true || validators.lastName === true || validators.dateOfBirth === true
            || validators.street1 === true || validators.city === true || validators.state === true || validators.zip === true 
            || validators.productNumber === true || validators.markerSize === true)
        {
            props.setSnackbar({message: "The form is not properly filled out. Please check input for errors and try again.", severity: "error", open: true });
            return;
        }
    
        props.setBackdrop(true);
        toggleSending(true);

        // eslint-disable-next-line no-console
        axios.patch(`${process.env.REACT_APP_API_URI}/Order`, {
            id: orderId,
            customerId: customer,
            markerFirstName: firstName,
            markerMiddleName: middleName,
            markerLastName:  lastName,
            markerDateOfBirth: new Date(dateOfBirth),
            markerDateOfDeath: (dateOfDeath !== null && dateOfDeath !== "") ? new Date(dateOfDeath) : null,
            inscription: inscription,
            status: orderStatus,
            shippingContact: shippingContact,
            shippingAddress: useCustomerAddress ? null : {
                id: shippingAddressId,
                addressType: 2,
                addressLine1: street1,
                addressLine2: street2,
                city: city,
                state: state,
                zip: zip
            },
            markerSize: markerSize,
            productNumber: productNumber,
            notes: notes,
            description: description,
            color: color,
            vase: vase,
            vaseWithRing: vaseWithRing,
            hasProof: hasProof,
            estimateAmount: estimateAmount,
            trackingType: trackingType,
            trackingNumber: trackingNumber
        }, GetHeaders())
        .then((response) => {
          // Snackbar success
          props.setSnackbar({message: "Order Saved!", severity: "success", open: true });

          let id = response.data;
          let count = 0;
          images.forEach(img => {
            if (count > 0)
            {
                setTimeout(() => {
                    UploadImage(id, img);
                }, 2000);
            }
            else
            {
                UploadImage(id, img);
            }

            count++;
          });
          
          if (images.length <= 0)
          {
              history.push(`/search`);
          }
        })
        .catch((error) => {
            props.setSnackbar({message: "Error saving order data.", severity: 'error', open: true});
        })
        .finally((tings) =>
        {
            if (images.length <= 0)
            {
                // End the loading screen
                props.setBackdrop(false);
        
                // Enable Button
                toggleSending(false);
            }
        });
      };

      const UploadImage = (id, image, length, count) =>
      {
        props.setSnackbar({message: "Uploading Images", severity: "info", open: true });

        let formData = new FormData();
        formData.append('formFile', image);
        formData.append('fileName', image.name);

            axios.post(`${process.env.REACT_APP_API_URI}/Order/${id}/images`, formData, GetHeaders())
            .then((imageResponse) => {
                props.setSnackbar({message: "Order Images Saved!", severity: "success", open: true });
                if (count >= length)
                {
                    // End the loading screen
                    props.setBackdrop(false);
            
                    // Enable Button
                    toggleSending(false);
                    history.push(`/search`);
                }
            })
            .catch((imageError) => {
                props.setSnackbar({message: "Error saving order images.", severity: 'error', open: true});
            }) 
      };

    return (
        <Box 
            className={classes.dashboardContainer}
            component="form"
            onSubmit={handleSubmit}
        >
            <Paper className={classes.paperContainer}>
                <Grid container spacing={edit ? 4 : 2}>
                    <Grid xs={12} item className={classes.gridElements}>
                        <Typography variant='h4' >Order Details</Typography>
                        <hr />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <OrderQRCode 
                            name={`${firstName} ${middleName} ${lastName}`} 
                            orderId={orderId}
                        />
                    </Grid>
                    <Grid style={{marginBottom: 0}} xs={12} item className={classes.gridElements}>
                        <hr style={{width: '75%'}} />
                        <Typography variant='h6'>Quick Edits</Typography>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id={'status-label-id'} required>Order Status</InputLabel>
                            <Select
                                className={classes.textField}
                                id='status'
                                value={orderStatus}
                                onChange={(ele) => { setOrderStatus(ele.target.value) }}
                                labelId='status-label-id'
                                label='Order Status'
                                name='orderStatus'
                            >
                                <MenuItem value={1}>Ordered</MenuItem>
                                <MenuItem value={2}>Proof</MenuItem>
                                <MenuItem value={3}>Awaiting Customer Approval</MenuItem>
                                <MenuItem value={4}>Setup</MenuItem>
                                <MenuItem value={5}>Casting</MenuItem>
                                <MenuItem value={6}>Clean Up</MenuItem> 
                                <MenuItem value={7}>Shipped</MenuItem>
                                <MenuItem value={8}>Cancelled</MenuItem>
                                <MenuItem value={9}>Waiting For Payment</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        { orderStatus === 7 ?
                            <TextField
                                className={classes.textField}
                                id='trackingType-input'
                                label='Tracking Type'
                                select
                                name='trackingType'
                                onChange={(ele) => { setTrackingType(ele.target.value) }}
                                value={trackingType}
                            >
                                <MenuItem value={1}>Fedex</MenuItem>
                                <MenuItem value={2}>UPS</MenuItem>
                                <MenuItem value={3}>USPS</MenuItem>
                            </TextField>
                            :
                            <div></div>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        { orderStatus === 7 ?
                            <TextField
                                className={classes.textField}
                                id='trackingNumber-input'
                                label='Tracking Number'
                                name='trackingNumber'
                                onChange={(ele) => { setTrackingNumber(ele.currentTarget.value) }}
                                value={trackingNumber}
                            />
                            :
                            <div></div>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <Button
                            variant='contained'
                            color='primary'
                            type="submit"
                            disabled={isSending}
                        >
                            Save
                        </Button>
                    </Grid>
                    <hr style={{width: '75%'}} />
                    <Grid xs={12} item className={classes.gridElements}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={edit}
                                    onChange={(ele) => toggleEdit(ele.target.checked)}
                                    color='primary'
                                />
                            }
                            label='Edit'
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        { edit ?
                            <SelectWithAdd
                                label='Ordering Company'
                                id='orderingCompany-input'
                                name='customer'
                                value={customer}
                                onChange={(ele) => setCustomer(ele.target.value)}
                                data={customerList}
                                error={validators.customer}
                                onBlur={(e) => validateCustomer(e.target.value)}
                                helperText={ validators.customer && "Must have a customer selected."}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Ordering Company: ${companyName}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='firstName-input'
                                label='Marker First Name'
                                name='firstName'
                                required
                                onChange={(ele) => { setFirstName(ele.currentTarget.value) }}
                                value={firstName}
                                error={validators.firstName}
                                onBlur={(e) => validateFirstName(e.target.value)}
                                helperText={ validators.firstName && "Name must be longer than 2 characters."}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Marker First Name: ${firstName}`}
                            </FormLabel>
                        }
                        
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='middleName-input'
                                label='Marker Middle Name'
                                name='middleName'
                                onChange={(ele) => { setMiddleName(ele.currentTarget.value) }}
                                value={middleName}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Marker Middle Name: ${middleName}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='lastName-input'
                                label='Marker Last Name'
                                name='lastName'
                                required
                                onChange={(ele) => { setLastName(ele.currentTarget.value) }}
                                value={lastName}
                                error={validators.lastName}
                                onBlur={(e) => validateLastName(e.target.value)}
                                helperText={ validators.lastName && "Name must be longer than 2 characters."}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Marker Last Name: ${lastName}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    required
                                    className={classes.textField}
                                    label="Marker Date of Birth"
                                    id="dob-input"
                                    value={dateOfBirth}
                                    onChange={handleDOBChange}
                                    maxDate={Date.now()}
                                    renderInput={(params) => <TextField name='dateOfBirth' {...params} onBlur={(e) => validateBirthDate(e.target.value)} error={validators.dateOfBirth} required helperText={validators.dateOfBirth && "Birthdate is required."} />}
                                />
                            </LocalizationProvider>
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Marker Date of Birth: ${dateOfBirth !== null ? dateOfBirth.toDateString() : 'null'}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    className={classes.textField}
                                    label="Marker Date of Death"
                                    id="dod-input"
                                    value={dateOfDeath}
                                    onChange={handleDODChange}
                                    maxDate={Date.now()}
                                    renderInput={(params) => <TextField {...params} namej='dateOfDeath' helperText={params?.inputProps?.placeholder} />}
                                />
                            </LocalizationProvider>
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Marker Date of Death: ${dateOfDeath !== null ? dateOfDeath.toDateString(): 'null'}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <FormControl>
                                <FormLabel className={classes.label}>Inscription</FormLabel>
                                <TextareaAutosize 
                                    style={{minHeight: 50}}
                                    className={classes.textField}
                                    name='inscription'
                                    value={inscription}
                                    onChange={(ele) => { setInscription(ele.currentTarget.value) }}
                                />
                            </FormControl>
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Inscription: ${inscription}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                required
                                className={classes.textField}
                                id='markerSize-input'
                                label='Marker Size'
                                name='markerSize'
                                onChange={(ele) => { setMarkerSize(ele.currentTarget.value) }}
                                value={markerSize}
                                error={validators.markerSize}
                                onBlur={(e) => validateMarkerSize(e.target.value)}
                                helperText={ validators.markerSize && "Marker Size is required."}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Marker Size: ${markerSize}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <FormControl>
                                <FormLabel className={classes.label}>Description</FormLabel>
                                <TextareaAutosize 
                                    style={{minHeight: 50}}
                                    className={classes.textField}
                                    name='description'
                                    value={description}
                                    onChange={(ele) => { setDescription(ele.currentTarget.value) }}
                                />
                            </FormControl>
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Description: ${description}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='productNumber-input'
                                label='Product Number'
                                required
                                name='productNumber'
                                onChange={(ele) => { setProductNumber(ele.currentTarget.value) }}
                                value={productNumber}
                                error={validators.productNumber}
                                onBlur={(e) => validateProductNumber(e.target.value)}
                                helperText={ validators.productNumber && "Product Number is required."}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Product Number: ${productNumber}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='color-input'
                                label='Color'
                                select
                                required
                                name='color'
                                onChange={(ele) => { setColor(ele.target.value) }}
                                value={color}
                            >
                                <MenuItem value={"dark"}>Dark</MenuItem>
                                <MenuItem value={"light"}>Light</MenuItem>
                                <MenuItem value={"acid"}>Acid</MenuItem>
                                <MenuItem value={"custom"}>Custom (Add to description)</MenuItem>
                            </TextField>
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Color: ${color}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={vase}
                                            onChange={(ele) => setVase(ele.target.checked)}
                                            color='primary'
                                            name='vase'
                                        />
                                    }
                                    label='Vase:'
                                />
                                <FormHelperText>(in the marker)</FormHelperText>
                            </FormControl>
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Vase: ${vase}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={vaseWithRing}
                                            onChange={(ele) => setVaseWithRing(ele.target.checked)}
                                            color='primary'
                                            name='vaseWithRing'
                                        />
                                    }
                                    label='Vase with Ring:'
                                />
                                <FormHelperText>(separate from marker)</FormHelperText>
                            </FormControl>
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Vase with Ring: ${vaseWithRing}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={hasProof}
                                            onChange={(ele) => setHasProof(ele.target.checked)}
                                            color='primary'
                                            name='hasProof'
                                        />
                                    }
                                    label='Proof:'
                                />
                                <FormHelperText>Upload Proof to images.</FormHelperText>
                            </FormControl>
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Proof: ${hasProof}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='estimateAmount-input'
                                label='Estimate Amount'
                                name='estimateAmount'
                                type="number"
                                onChange={(ele) => { setEstimateAmount(ele.currentTarget.value) }}
                                value={estimateAmount}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Estimated Amount: ${estimateAmount}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextareaAutosize 
                                style={{minHeight: 50}}
                                className={classes.textField}
                                name='notes'
                                value={notes}
                                onChange={(ele) => { setNotes(ele.currentTarget.value) }}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Notes: ${notes}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <FormControl>
                                <FormLabel className={classes.label}>Images</FormLabel>
                                <ImageUploader
                                    className={classes.textField}
                                    withIcon={true}
                                    buttonText='Upload Images'
                                    imgExtension={['.jpg', '.png', '.jpeg']}
                                    maxFileSize={20971520}
                                    name='images'
                                    onChange={onDrop}
                                    withLabel
                                    withPreview
                                />
                            </FormControl>
                            :
                            <Carousel images={imageUris}/>
                        }
                    </Grid>
                    <Grid style={{marginBottom: 0}} xs={12} item className={classes.gridElements}>
                        <hr style={{width: '75%'}} />
                        <Typography variant='h6'>Shipping Address</Typography>
                    </Grid>
                    <hr style={{width: '75%'}} />
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useCustomerAddress}
                                        onChange={(ele) => setUseCustomerAddress(ele.target.checked)}
                                        color='primary'
                                    />
                                }
                                label='Use Customer Address'
                            />
                            :
                            <div></div>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='shippingContact-input'
                                label='Shipping Contact'
                                name='shippingContact'
                                onChange={(ele) => { setShippingContact(ele.currentTarget.value) }}
                                value={shippingContact}
                                helperText='The TO: for the shipping label.'
                                disabled={useCustomerAddress}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Shipping Contact: ${shippingContact}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='street1-input'
                                label='Street 1'
                                required
                                onChange={(ele) => { setStreet1(ele.currentTarget.value) }}
                                value={street1}
                                name='street1'
                                error={validators.street1}
                                onBlur={(e) => validateStreet1(e.target.value)}
                                helperText={ validators.street1 && "Street 1 is invalid."}
                                disabled={useCustomerAddress}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Street 1: ${street1}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='street2-input'
                                label='Street 2'
                                onChange={(ele) => { setStreet2(ele.currentTarget.value) }}
                                value={street2}
                                name='street2'
                                disabled={useCustomerAddress}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Street 2: ${street2}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='city-input'
                                label='City'
                                required
                                onChange={(ele) => { setCity(ele.currentTarget.value) }}
                                value={city}
                                name='city'
                                error={validators.city}
                                onBlur={(e) => validateCity(e.target.value)}
                                helperText={ validators.city && "City is invalid."}
                                disabled={useCustomerAddress}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`City: ${city}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <StateTextBox
                                label='State'
                                id='state-input'
                                value={state}
                                name='state'
                                onChange={(ele) => { setState(ele.target.value) }}
                                error={validators.state}
                                onBlur={(e) => validateState(e.target.value)}
                                helperText={ validators.state && "State is invalid."}
                                disabled={useCustomerAddress}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`State: ${state}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='zip-input'
                                label='Zip'
                                required
                                onChange={(ele) => { setZip(ele.currentTarget.value) }}
                                value={zip}
                                name='zip'
                                InputProps={{
                                    inputComponent: NumberTextMask,
                                    notched: true
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                error={validators.zip}
                                onBlur={(e) => validateZip(e.target.value)}
                                helperText={ validators.zip && "Zip is invalid."}
                                disabled={useCustomerAddress}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Zip: ${zip}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <OrderQRCode 
                            name={`${firstName} ${middleName} ${lastName}`} 
                            orderId={orderId}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            type="submit"
                            disabled={isSending}
                        >
                            Save
                        </Button>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        All things
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
  }
  
  export default OrderDetails;