import React from 'react';
import { InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';

import * as constants from '../common/constants';

const useStyles = makeStyles((theme) => ({
    gridElements: {
        marginBottom: '.5em'
    },
    select: {
        width: 250,
    },
    addButton: {
        position: 'inherit'
    }
  }));

  function StateTextBox(props) {
    const classes = useStyles();

    return (
        <div>
            <FormControl className={classes.formControl}
                inputProps={{notched: true}}

                required
            >
                <InputLabel id={`${props.label}-id`} shrink={true}>{props.label}</InputLabel>
                <Select
                    className={classes.select}
                    id={props.id}
                    value={props.value}
                    onChange={props.onChange}
                    labelId={`${props.label}-id`}
                    label={props.label}
                    name={props.name}
                    error={props.error}
                    onBlur={props.onBlur}
                    helperText={props.helperText}
                    disabled={props.disabled}
                >
                    {constants.StateList.map((menuItem) => (
                        <MenuItem key={menuItem.abbreviation} value={menuItem.abbreviation}>{menuItem.name} - {menuItem.abbreviation}</MenuItem> 
                    ))}
                </Select>
            </FormControl>
        </div>
    );
  };

  export default StateTextBox;