import 'date-fns';
import React from 'react';
import { Checkbox, FormControlLabel, FormHelperText, Grid, Paper, Typography, TextField, TextareaAutosize, FormControl, FormLabel, Button, MenuItem, Box, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ImageUploader from 'react-images-upload';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';

import SelectWithAdd from '../components/selectWithAdd';
import StateTextBox from '../components/stateTextBox';
import NumberTextMask from '../components/numberTextMask';
import { GetHeaders } from '../utils/utils';

const useStyles = makeStyles((theme) => ({
    dashboardContainer: {
        marginTop: '1em',
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20
    },
    gridElements: {
        marginBottom: '.5em'
    },
    textField: {
        width: 250,
        maxWidth: 250,
        minWidth:250
    },
    paperContainer: {
        padding: 5
    },
    label: {
        textAlign: 'left'
    }
  }));

  function Order(props) {
    const classes = useStyles();
    let history = useHistory();
    const search = useLocation().search;

    const [customer, setCustomer] = React.useState(" ");
    const [firstName, setFirstName] = React.useState();
    const [middleName, setMiddleName] = React.useState();
    const [lastName, setLastName] = React.useState();    
    const [dateOfBirth, setDOB] = React.useState(null);    
    const [dateOfDeath, setDOD] = React.useState(null);
    const [inscription, setInscription] = React.useState();
    const [markerSize, setMarkerSize] = React.useState();
    const [description, setDescription] = React.useState("");
    const [productNumber, setProductNumber] = React.useState();
    const [color, setColor] = React.useState("dark");
    const [vase, setVase] = React.useState(false);
    const [vaseWithRing, setVaseWithRing] = React.useState(false);
    const [hasProof, setHasProof] = React.useState(false);
    const [estimateAmount, setEstimateAmount] = React.useState();
    const [notes, setNotes] = React.useState();
    const [images, setImages] = React.useState([]);
    const [useCustomerAddress, setUseCustomerAddress] = React.useState(false);
    const [shippingContact, setShippingContact] = React.useState();
    const [street1, setStreet1] = React.useState();    
    const [street2, setStreet2] = React.useState();       
    const [city, setCity] = React.useState();    
    const [state, setState] = React.useState(' ');
    const [zip, setZip] = React.useState();
    const [customerList, setCustomerList] = React.useState([]);
    const [isSending, toggleSending] = React.useState(false);
    const [validators, setValidations] = React.useState({customer: false, firstName: false, lastName: false, dateOfBirth: false, markerSize: false, productNumber: false, street1: false, city: false, state: false, zip: false});

    const handleDOBChange = (date) => {
        setDOB(date);
    };

    const handleDODChange = (date) => {
        setDOD(date);
    };

    const onDrop = (pictureFiles, pictureDataURLs) => {
        setImages(images.concat(pictureFiles));
    };

    React.useEffect(() => {
        // Start the loading screen
        props.setBackdrop(true);

        // Gets the schedule services availability
        axios.get(`${process.env.REACT_APP_API_URI}/Customer/list`, GetHeaders())
            .then((response) => {
                setCustomerList(response.data);
            })
            .catch((error) => {
                props.setSnackbar({message: 'Error loading customer list.', severity: 'error', open: true});
            })
            .finally((tings) => {
                // End the loading screen
                props.setBackdrop(false);
            });

        const queryParams = new URLSearchParams(search);

        if (queryParams.has('cid')) {

            setCustomer(queryParams.get('cid'));
        }
    }, []);

    const validateCustomer = (customer) => {
        setValidations({...validators, customer: customer <= 0 || customer === null || customer === "" || customer === undefined });
    };

    const validateFirstName = (firstName) => {
        setValidations({...validators, firstName: firstName.length <= 2});
    };

    const validateLastName = (lastName) => {
        setValidations({...validators, lastName: lastName.length <= 2});
    };

    const validateBirthDate = (dateOfBirth) => {
        setValidations({...validators, dateOfBirth: dateOfBirth === null || dateOfBirth === ""});
    };

    const validateMarkerSize = (markerSize) => {
        setValidations({...validators, markerSize: markerSize.length <= 0});
    };

    const validateProductNumber = (productNumber) => {
        setValidations({...validators, productNumber: productNumber.length <= 0});
    };

    const validateStreet1 = (street1) => {
        setValidations({...validators, street1: street1.length <= 2});
    };

    const validateCity = (city) => {
        setValidations({...validators, city: city.length < 2});
    };

    const validateState = (state) => {
        setValidations({...validators, state: state.length < 2});
    };

    const validateZip = (zip) => {
        setValidations({...validators, zip: zip.length < 5});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (validators.customer === true || validators.firstName === true || validators.lastName === true || validators.dateOfBirth === true
            || validators.street1 === true || validators.city === true || validators.state === true || validators.zip === true 
            || validators.productNumber === true || validators.markerSize === true)
        {
            props.setSnackbar({message: "The form is not properly filled out. Please check input for errors and try again.", severity: "error", open: true });
            return;
        }
    
        props.setBackdrop(true);
        toggleSending(true);
        
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        axios.post(`${process.env.REACT_APP_API_URI}/Order`, {
            customerId: data.get('customer'),
            markerFirstName: data.get('firstName'),
            markerMiddleName: data.get('middleName'),
            markerLastName:  data.get('lastName'),
            markerDateOfBirth: new Date(data.get('dateOfBirth')),
            markerDateOfDeath: (data.get('dateOfDeath') !== null && data.get('dateOfDeath') !== "") ? new Date(data.get('dateOfDeath')) : null,
            inscription: data.get('inscription'),
            status: 1,
            shippingContact: data.get('shippingContact'),
            shippingAddress: {
                addressType: 2,
                addressLine1: data.get('street1'),
                addressLine2: data.get('street2'),
                city: data.get('city'),
                state: data.get('state'),
                zip: data.get('zip')
            },
            markerSize: data.get('markerSize'),
            productNumber: data.get('productNumber'),
            notes: data.get('notes'),
            description: data.get('description'),
            color: data.get('color'),
            vase: data.get('vase') === "on" ? true : false,
            vaseWithRing: data.get('vaseWithRing') === "on" ? true : false,
            hasProof: data.get('hasProof') === "on" ? true : false,
            estimateAmount: data.get('estimateAmount') ? data.get('estimateAmount') : null,
            isCustomerAddress: useCustomerAddress
        }, GetHeaders())
        .then((response) => {
          // Snackbar success
          props.setSnackbar({message: "Order Created!", severity: "success", open: true });

          let id = response.data;
          let count = 0;
          images.forEach(img => {
            if (count > 0)
            {
                setTimeout(() => {
                    UploadImage(id, img, images.length - 1, count);
                }, 2000);
            }
            else
            {
                UploadImage(id, img, images.length - 1, count);
            }

            count++;
          });
          
            if (images.length <= 0)
            {
                history.push(`/order-details/${id}`);
            }
        })
        .catch((error) => {
            props.setSnackbar({message: "Error creating order.", severity: 'error', open: true});
        })
        .finally((tings) =>
        {
            if (images.length <= 0)
            {
                // End the loading screen
                props.setBackdrop(false);
        
                // Enable Button
                toggleSending(false);
            }
        });
      };

      const UploadImage = (id, image, length, count) =>
      {
        let formData = new FormData();
        formData.append('formFile', image);
        formData.append('fileName', image.name);

            axios.post(`${process.env.REACT_APP_API_URI}/Order/${id}/images`, formData, GetHeaders())
            .then((imageResponse) => {
                props.setSnackbar({message: "Order Image Saved!", severity: "success", open: true });
                if (count >= length)
                {
                    // End the loading screen
                    props.setBackdrop(false);
            
                    // Enable Button
                    toggleSending(false);
                    history.push(`/order-details/${id}`);

                }
            })
            .catch((imageError) => {
                props.setSnackbar({message: "Error saving order images.", severity: 'error', open: true});
            }) 
      };

    return (
        <Box 
            className={classes.dashboardContainer}
            component="form"
            onSubmit={handleSubmit}
        >
            <Paper className={classes.paperContainer}>
                <Grid container spacing={4}>
                    <Grid xs={12} item className={classes.gridElements}>
                        <Typography variant='h4' >New Order</Typography>
                        <hr />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <SelectWithAdd
                            label='Ordering Company'
                            id='orderingCompany-input'
                            name='customer'
                            value={customer}
                            onChange={(ele) => setCustomer(ele.target.value)}
                            data={customerList}
                            error={validators.customer}
                            onBlur={(e) => validateCustomer(e.target.value)}
                            helperText={ validators.customer && "Must have a customer selected."}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <TextField
                            className={classes.textField}
                            id='firstName-input'
                            label='Marker First Name'
                            name='firstName'
                            required
                            onChange={(ele) => { setFirstName(ele.currentTarget.value) }}
                            value={firstName}
                            error={validators.firstName}
                            onBlur={(e) => validateFirstName(e.target.value)}
                            helperText={ validators.firstName && "Name must be longer than 2 characters."}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <TextField
                            className={classes.textField}
                            id='middleName-input'
                            label='Marker Middle Name'
                            name='middleName'
                            onChange={(ele) => { setMiddleName(ele.currentTarget.value) }}
                            value={middleName}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <TextField
                            className={classes.textField}
                            id='lastName-input'
                            label='Marker Last Name'
                            name='lastName'
                            required
                            onChange={(ele) => { setLastName(ele.currentTarget.value) }}
                            value={lastName}
                            error={validators.lastName}
                            onBlur={(e) => validateLastName(e.target.value)}
                            helperText={ validators.lastName && "Name must be longer than 2 characters."}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                required
                                className={classes.textField}
                                label="Marker Date of Birth"
                                id="dob-input"
                                value={dateOfBirth}
                                onChange={handleDOBChange}
                                maxDate={Date.now()}
                                renderInput={(params) => <TextField name='dateOfBirth' {...params} onBlur={(e) => validateBirthDate(e.target.value)} error={validators.dateOfBirth} required helperText={validators.dateOfBirth && "Birthdate is required."} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                className={classes.textField}
                                label="Marker Date of Death"
                                id="dod-input"
                                value={dateOfDeath}
                                onChange={handleDODChange}
                                maxDate={Date.now()}
                                renderInput={(params) => <TextField {...params} name='dateOfDeath' helperText={params?.inputProps?.placeholder} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <FormControl>
                            <FormLabel className={classes.label}>Inscription</FormLabel>
                            <TextareaAutosize 
                                style={{minHeight: 50}}
                                className={classes.textField}
                                name='inscription'
                                value={inscription}
                                onChange={(ele) => { setInscription(ele.currentTarget.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <TextField
                            required
                            className={classes.textField}
                            id='markerSize-input'
                            label='Marker Size'
                            name='markerSize'
                            onChange={(ele) => { setMarkerSize(ele.currentTarget.value) }}
                            value={markerSize}
                            error={validators.markerSize}
                            onBlur={(e) => validateMarkerSize(e.target.value)}
                            helperText={ validators.markerSize && "Marker Size is required."}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <FormControl>
                            <FormLabel className={classes.label}>Description</FormLabel>
                            <TextareaAutosize 
                                style={{minHeight: 50}}
                                className={classes.textField}
                                name='description'
                                value={description}
                                onChange={(ele) => { setDescription(ele.currentTarget.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <TextField
                            className={classes.textField}
                            id='productNumber-input'
                            label='Product Number'
                            required
                            name='productNumber'
                            onChange={(ele) => { setProductNumber(ele.currentTarget.value) }}
                            value={productNumber}
                            error={validators.productNumber}
                            onBlur={(e) => validateProductNumber(e.target.value)}
                            helperText={ validators.productNumber && "Product Number is required."}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <TextField
                            className={classes.textField}
                            id='color-input'
                            label='Color'
                            select
                            required
                            name='color'
                            onChange={(ele) => { setColor(ele.target.value) }}
                            value={color}
                        >
                            <MenuItem value={"dark"}>Dark</MenuItem>
                            <MenuItem value={"light"}>Light</MenuItem>
                            <MenuItem value={"acid"}>Acid</MenuItem>
                            <MenuItem value={"custom"}>Custom (Add to description)</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={vase}
                                        onChange={(ele) => setVase(ele.target.checked)}
                                        color='primary'
                                        name='vase'
                                    />
                                }
                                label='Vase:'
                            />
                            <FormHelperText>(in the marker)</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={vaseWithRing}
                                        onChange={(ele) => setVaseWithRing(ele.target.checked)}
                                        color='primary'
                                        name='vaseWithRing'
                                    />
                                }
                                label='Vase with Ring:'
                            />
                            <FormHelperText>(separate from marker)</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasProof}
                                        onChange={(ele) => setHasProof(ele.target.checked)}
                                        color='primary'
                                        name='hasProof'
                                    />
                                }
                                label='Proof:'
                            />
                            <FormHelperText>Upload Proof to images.</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <FormControl>
                            <FormLabel className={classes.label}>Notes</FormLabel>
                            <TextareaAutosize 
                                style={{minHeight: 50}}
                                className={classes.textField}
                                name='notes'
                                value={notes}
                                onChange={(ele) => { setNotes(ele.currentTarget.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <TextField
                            className={classes.textField}
                            id='estimateAmount-input'
                            label='Estimate Amount'
                            name='estimateAmount'
                            type="number"
                            onChange={(ele) => { setEstimateAmount(ele.currentTarget.value) }}
                            value={estimateAmount}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <FormControl>
                            <FormLabel className={classes.label}>Images</FormLabel>
                            <ImageUploader
                                className={classes.textField}
                                withIcon={true}
                                buttonText='Upload Images'
                                imgExtension={['.jpg', '.png', '.jpeg']}
                                maxFileSize={20971520}
                                name='images'
                                onChange={onDrop}
                                withLabel
                                withPreview
                            />
                        </FormControl>
                    </Grid>
                    <Grid style={{marginBottom: 0}} xs={12} item className={classes.gridElements}>
                        <hr style={{width: '75%'}} />
                        <Typography variant='h6'>Shipping Address</Typography>
                    </Grid>
                    <hr style={{width: '75%'}} />
                    <Grid xs={12} item className={classes.gridElements}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={useCustomerAddress}
                                    onChange={(ele) => setUseCustomerAddress(ele.target.checked)}
                                    color='primary'
                                />
                            }
                            label='Use Customer Address'
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <TextField
                            className={classes.textField}
                            id='shippingContact-input'
                            label='Shipping Contact'
                            name='shippingContact'
                            onChange={(ele) => { setShippingContact(ele.currentTarget.value) }}
                            value={shippingContact}
                            helperText='The TO: for the shipping label.'
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <TextField
                            className={classes.textField}
                            id='street1-input'
                            label='Street 1'
                            required={!useCustomerAddress}
                            onChange={(ele) => { setStreet1(ele.currentTarget.value) }}
                            value={street1}
                            name='street1'
                            error={validators.street1}
                            onBlur={useCustomerAddress ? () => {} : (e) => validateStreet1(e.target.value)}
                            helperText={ validators.street1 && "Street 1 is invalid."}
                            disabled={useCustomerAddress}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <TextField
                            className={classes.textField}
                            id='street2-input'
                            label='Street 2'
                            onChange={(ele) => { setStreet2(ele.currentTarget.value) }}
                            value={street2}
                            name='street2'
                            disabled={useCustomerAddress}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <TextField
                            className={classes.textField}
                            id='city-input'
                            label='City'
                            required={!useCustomerAddress}
                            onChange={(ele) => { setCity(ele.currentTarget.value) }}
                            value={city}
                            name='city'
                            error={validators.city}
                            onBlur={useCustomerAddress ? () => {} : (e) => validateCity(e.target.value)}
                            helperText={ validators.city && "City is invalid."}
                            disabled={useCustomerAddress}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <StateTextBox
                            label='State'
                            id='state-input'
                            value={state}
                            name='state'
                            onChange={(ele) => { setState(ele.target.value) }}
                            error={validators.state}
                            onBlur={useCustomerAddress ? () => {} : (e) => validateState(e.target.value)}
                            helperText={ validators.state && "State is invalid."}
                            disabled={useCustomerAddress}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <TextField
                            className={classes.textField}
                            id='zip-input'
                            label='Zip'
                            required={!useCustomerAddress}
                            onChange={(ele) => { setZip(ele.currentTarget.value) }}
                            value={zip}
                            name='zip'
                            InputProps={{
                                inputComponent: NumberTextMask
                            }}
                            error={validators.zip}
                            onBlur={useCustomerAddress ? () => {} : (e) => validateZip(e.target.value)}
                            helperText={ validators.zip && "Zip is invalid."}
                            disabled={useCustomerAddress}
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            type="submit"
                            disabled={isSending}
                        >
                            Save
                        </Button>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        All things
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
  }
  
  export default Order;