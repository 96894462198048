import React from 'react';
import QRCode from 'qrcode.react';
import { makeStyles } from '@mui/styles';
import { Button, AppBar, Toolbar, IconButton, Typography, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    textField: {
        width: 250,
        maxWidth: 250,
        minWidth: 200
    },
    canvas: {
        margin: 'auto'
    }
  }));

function OrderQRCode(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const printQRCode = () => {
        let printwin = window.open("");
        let canvas = document.getElementById('qr-code');
        printwin.document.write("<div style='text-align: center; margin-top: 20px;'><img width='250px' src='"+canvas.toDataURL()+"'/><div style='margin-top: 15px; font-size: xx-large'>"+props.name+"</div><div style='font-size: xx-large'>OrderId - "+props.orderId+"</div></div>");
    };

    return (
        <div style={{alignContent: 'center'}}>
            <Button variant="outlined" color='secondary' className={classes.textField} onClick={handleClickOpen}>
                PRINT QR CODE
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    >
                    <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Order QR Code
                    </Typography>
                    <Button
                        style={{color: 'white'}}
                        onClick={printQRCode}
                    >
                        Print
                    </Button>
                </Toolbar>
                </AppBar>
                <QRCode className={classes.canvas} id="qr-code" size={250} value={window.location.href} />
            </Dialog>
        </div>
    );
};

export default OrderQRCode;