import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    homeContainer: {
        marginTop: '1em'
    },
    gridElements: {
        marginBottom: '.5em'
    }
  }));

  function Home() {
    const classes = useStyles();

    const isLoggedIn = () => {
        let result = document.cookie.split('; ').find(row => row.startsWith('AuthJWT=')) !== null && document.cookie.split('; ').find(row => row.startsWith('AuthJWT=')) !== undefined && !document.cookie.split('; ').find(row => row.startsWith('AuthJWT=')).includes("undefined");
        return result;
      };

    return (
        <div className={classes.homeContainer}>
            <Grid container>
                <Grid xs={12} item className={classes.gridElements}>
                    <img src='/EagleFoundryTransparantLogo.png' alt='eagleFoundryLogo' />
                </Grid>
                <Grid xs={12} item className={classes.gridElements}>
                    <Typography variant='h4' >Welcome to Eagle Foundry Order Tracker!</Typography>
                </Grid>
                <Grid xs={12} item className={classes.gridElements}>
                    <Typography variant='h6' >Get an update on your order's status now!</Typography>
                </Grid>
                <Grid xs={12} item className={classes.gridElements}>
                    <Button 
                        color='secondary' 
                        size='large' 
                        variant='contained' 
                        href='/track-order'
                    >
                        Check Order
                    </Button>
                </Grid>
                {isLoggedIn() ?
                    <Grid style={{marginTop: 30}} xs={12} item className={classes.gridElements}>
                        <Button
                            color="secondary"
                            fullWidth
                            variant='contained'
                            href='/dashboard'
                            >
                                Employee Dashboard
                            </Button>
                    </Grid>
                :
                    <div/>
                }
                
            </Grid>
        </div>
    );
  }
  
  export default Home;