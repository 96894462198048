import mobile from 'is-mobile';

export function isSmallScreen() {
    return mobile();
};

export const GetHeaders = () =>
{
    return {
        headers: {
            Authorization: document.cookie && `${document.cookie.split('; ').find(row => row.startsWith('AuthJWT=')).split('=')[1]}`
        }
    };
};