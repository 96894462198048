import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useHistory } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

  
  function BottomNav() {
    const history = useHistory();
  
    let defaultNavState = '';

    switch(window.location.pathname) {
        case '/new-order':
        case '/search':
        case '/dashboard':
        case '/qr-code':
            defaultNavState = window.location.pathname;
            break;
        default:
            break;
    }

    const [navValue, setNav] = React.useState(defaultNavState);
  
    return (
        <BottomNavigation
            value={navValue}
            onChange={(event, newValue) => {
                setNav(newValue);
            }}
            showLabels
            style={{position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }}
        >
            <BottomNavigationAction label='New Order' value='/new-order' icon={<NoteAddIcon />} onClick={() => history.push('/new-order')} />
            <BottomNavigationAction label='New Customer' value='/new-customer' icon={<PersonAddIcon />} onClick={() => history.push('/new-customer')} />
            <BottomNavigationAction label='Dashboard' value='/dashboard' icon={<DashboardIcon />} onClick={() => history.push('/dashboard')} />
            <BottomNavigationAction label='Search' value='/search' icon={<SearchIcon />} onClick={() => history.push('/search')} />
        </BottomNavigation>
    );
  }
  
  export default BottomNav;