import MaskedInput from 'react-text-mask';

function NumberTextMask(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/,]}
        placeholderChar={'\u2000'}
        ref={inputRef}
      />
    );
  }

  export default NumberTextMask;