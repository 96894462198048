import React, { useEffect } from 'react';
import { Box, Container, Button, Typography, Avatar } from '@mui/material';
import { useHistory } from 'react-router-dom';
import mobile from 'is-mobile';

import Copyright from '../components/copyright';

function SignOut(props) {
    let history = useHistory();

    useEffect(() => {
        props.setBackdrop(true);
        document.cookie="AuthJWT=;max-age=-999999;";
        props.setBackdrop(false);
    }, []);

    return (
        <Box
            sx={{
                marginTop: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                height: '100%',
                width: '100vw'
            }}
        >
            <Container maxWidth="sm" sx={{ alignItems: 'center', display: 'block'}}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main', display: 'inline-flex' }}>
                    <img src="/EF_Icon.png" alt="EagleFoundryIcon" width="300%" />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign Out
                </Typography>
                <Typography sx={{width: mobile() ? "225px" : "260px", margin: "0 auto", marginTop: '2em'}} variant="subtitle1">You have been signed out.</Typography>
                <div style={{display: 'block', marginTop: '1em'}}>
                    <Button 
                        color='primary' 
                        size='large' 
                        variant='contained'
                        sx={{marginTop: '2em', marginBottom: '1em'}}
                        onClick={() => history.push('/home') }
                    >
                        Home
                    </Button>
                </div>
            </Container>
            <Copyright sx={{ mt: 8, mb: 4}} />
        </Box>
    );
  }
  
  export default SignOut;