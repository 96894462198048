import React from 'react';
import { Snackbar, Stack } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Snackybar(props) {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        props.setSnackbar({message: '', severity: '', open: false});
    };

    return (
        <Stack spacing={2} sx={{ width: '100%'}}>
            <Snackbar open={props?.snackbar?.open || false} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={props?.snackbar?.severity || ''} sx={{width: '100%'}}>
                    {props?.snackbar?.message || ''}
                </Alert>
            </Snackbar>
        </Stack>
    );
}