import React from 'react';
import BearCarousel, { BearSlideItem } from "bear-react-carousel";
import "bear-react-carousel/dist/index.css";
import {v4 as uuidv4} from 'uuid';
import { makeStyles } from '@mui/styles';
import { Button, AppBar, Toolbar, IconButton, Typography, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import { isSmallScreen } from '../utils/utils';
  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    textField: {
        width: 250,
        maxWidth: 250,
        minWidth: 200
    }
  }));

  function Carousel(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const bearSlideItemData = (images) => 
    {
        let itemData = [];

        images.map((imageUri) => (
            itemData.push({ key: uuidv4(), children: <BearSlideItem imageUrl={imageUri} />})
        ));

        return itemData;
    }

    return (
        <div style={{alignContent: 'center'}}>
            <Button variant="outlined" color='secondary' className={classes.textField} onClick={handleClickOpen}>
                VIEW UPLOADED IMAGES
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    >
                    <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Order Images
                    </Typography>
                </Toolbar>
                </AppBar>
                <BearCarousel
                    data={bearSlideItemData(props.images)}
                    isEnableLoop
                    isEnableNavButton
                    isEnablePagination
                    isCenteredSlides
                    aspectRatio={{ widthRatio: isSmallScreen() ? 16 : 9, heightRatio: isSmallScreen() ? 9 : 16 }}
                    style={{maxWidth: isSmallScreen() ? '100%' : 'calc(100% - 10px)'}}
                />
            </Dialog>
        </div>
    );
  };
  
  export default Carousel;