import React from 'react';
import { Checkbox, FormControlLabel, Grid, Paper, Typography, TextField, FormLabel, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import PhoneTextMask from '../components/phoneTextMask';
import NumberTextMask from '../components/numberTextMask';
import StateTextBox from '../components/stateTextBox';
import { GetHeaders } from '../utils/utils';

const useStyles = makeStyles((theme) => ({
    dashboardContainer: {
        marginTop: '1em',
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20
    },
    gridElements: {
        marginBottom: '.5em'
    },
    textField: {
        width: 250,
        maxWidth: 250,
        minWidth: 200
    },
    paperContainer: {
        padding: 5
    },
    label: {
        textAlign: 'left'
    }
  }));

  function CustomerDetails(props) {
    const { id } = useParams();

    const classes = useStyles();
    const [edit, toggleEdit] = React.useState(false);
    const [customerId, setCustomerId] = React.useState();
    const [name, setName] = React.useState();
    const [contactName, setContactName] = React.useState();
    const [phone, setPhone] = React.useState('');
    const [fax, setFax] = React.useState('');
    const [email, setEmail] = React.useState();
    const [billingAddressId, setBillingAddressId] = React.useState();
    const [street1, setStreet1] = React.useState();    
    const [street2, setStreet2] = React.useState();       
    const [city, setCity] = React.useState();    
    const [state, setState] = React.useState(' ');
    const [zip, setZip] = React.useState();
    const [isSending, toggleSending] = React.useState(false);
    const [validators, setValidations] = React.useState({email: false, name: false, contactName: false, phone: false, fax: false, street1: false, city: false, state: false, zip: false});

    const formatPhone = (phoneNumber) => {
        let formattedPhone = 'None';
        if (phoneNumber)
        {
            let match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

            if (match) {
                formattedPhone = `(${match[1]}) ${match[2]}-${match[3]}`;
              };
        }
       
        return formattedPhone;
    };


    React.useEffect(() => {
        props.setBackdrop(true);

        axios.get(`${process.env.REACT_APP_API_URI}/customer/${id}`, GetHeaders())
            .then((response) => {
                setCustomerId(response.data.id);
                setName(response.data.companyName);
                setContactName(response.data.contact);
                setPhone(response.data.phone);
                setFax(response.data.fax);
                setEmail(response.data.email);
                setBillingAddressId(response.data.billingAddress.id);
                setStreet1(response.data.billingAddress.addressLine1);
                setStreet2(response.data.billingAddress.addressLine2);
                setCity(response.data.billingAddress.city);
                setState(response.data.billingAddress.state);
                setZip(response.data.billingAddress.zip);
            })
            .catch((error) => {
                props.setSnackbar({message: "Error loading customer data.", severity: 'error', open: true});
            })
            .finally((tings) => {
                // End the loading screen
                props.setBackdrop(false);
            });

    }, []);

    const validateName = (name) => {
        setValidations({...validators, name: name.length <= 2});
    };

    const validateContactName = (contactName) => {
        setValidations({...validators, contactName: contactName.length <= 2});
    };

    const validatePhone = (phone) => {
        setValidations({...validators, phone: phone.match(/\d/g) == null || phone.match(/\d/g).join("").length <= 9});
    };

    const validateFax = (fax) => {
        setValidations({...validators, fax: fax.match(/\d/g) != null && fax.match(/\d/g).join("").length < 9 });
    };

    const validateEmail = (email) => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setValidations({...validators, email: !emailRegex.test(String(email).toLowerCase())});
    };

    const validateStreet1 = (street1) => {
        setValidations({...validators, street1: street1.length <= 2});
    };

    const validateCity = (city) => {
        setValidations({...validators, city: city.length < 2});
    };

    const validateState = (state) => {
        setValidations({...validators, state: state.length < 2});
    };

    const validateZip = (zip) => {
        setValidations({...validators, zip: zip.length < 5});
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (validators.email)
        {
            props.setSnackbar({message: "The form is not properly filled out. Please check input for errors and try again.", severity: "error", open: true });
            return;
        }
    
        props.setBackdrop(true);
        toggleSending(true);
        
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        axios.patch(`${process.env.REACT_APP_API_URI}/Customer`, {
            id: customerId,
            companyName: data.get('name'),
            contact: data.get('contactName'),
            phone: data.get('phone').match(/\d/g).join(""),
            fax:  data.get('fax') != null ? data.get('fax').match(/\d/g) != null ? data.get('fax').match(/\d/g).join("") : null : null,
            email: data.get('email'),
            billingAddress: {
                id: billingAddressId,
                addressType: 1,
                addressLine1: data.get('street1'),
                addressLine2: data.get('street2'),
                city: data.get('city'),
                state: data.get('state'),
                zip: data.get('zip')
            }
        }, GetHeaders())
        .then((response) => {
          // Snackbar success
          props.setSnackbar({message: "Save successful!", severity: "success", open: true });
        })
        .catch((error) => {
            props.setSnackbar({message: "Error saving customer data.", severity: 'error', open: true});

        })
        .finally((tings) =>
        {
            // End the loading screen
            props.setBackdrop(false);
    
            // Enable Button
            toggleSending(false);
        });
      };

    return (
        <Box 
            className={classes.dashboardContainer}
            component="form"
            onSubmit={handleSubmit}
        >
            <Paper className={classes.paperContainer}>
                <Grid container spacing={edit ? 4 : 2}>
                    <Grid xs={12} item className={classes.gridElements}>
                        <Typography variant='h4' >Customer Details</Typography>
                        <hr />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={edit}
                                    onChange={(ele) => toggleEdit(ele.target.checked)}
                                    color='primary'
                                />
                            }
                            label='Edit'
                        />
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        { edit ?
                            <TextField
                                className={classes.textField}
                                label='Company Name'
                                id='name-input'
                                onChange={(ele) => setName(ele.currentTarget.value)}
                                value={name}
                                name='name'
                                required
                                error={validators.name}
                                onBlur={(e) => validateName(e.target.value)}
                                helperText={ validators.name && "Name must be longer than 2 characters."}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Company Name: ${name}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        { edit ?
                            <TextField
                                className={classes.textField}
                                label='Company Contact Name'
                                id='contact-name-input'
                                onChange={(ele) => setContactName(ele.currentTarget.value)}
                                value={contactName}
                                name='contactName'
                                required
                                error={validators.contactName}
                                onBlur={(e) => validateContactName(e.target.value)}
                                helperText={ validators.contactName && "Contact Name must be longer than 2 characters."}
                            />  
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Company Contact Name: ${contactName}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='phone-input'
                                onChange={(ele) => setPhone(ele.currentTarget.value)}
                                value={phone}
                                label='Company Phone'
                                InputProps={{
                                    inputComponent: PhoneTextMask,
                                    notched: true,
                                    
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                name='phone'
                                required
                                error={validators.phone}
                                onBlur={(e) => validatePhone(e.target.value)}
                                helperText={ validators.phone && "A valid Phone is required."}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Company Phone: ${formatPhone(phone)}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='fax-input'
                                onChange={(ele) => setFax(ele.currentTarget.value)}
                                value={fax}
                                label='Company Fax'
                                InputProps={{
                                    inputComponent: PhoneTextMask,
                                    notched: true,
                                    
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                name='fax'
                                error={validators.fax}
                                onBlur={(e) => validateFax(e.target.value)}
                                helperText={ validators.fax && "Fax number is invalid."}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Fax: ${formatPhone(fax)}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='email-input'
                                label='Company Email'
                                onChange={(ele) => { setEmail(ele.currentTarget.value) }}
                                value={email}
                                name='email'
                                required
                                error={validators.email}
                                onBlur={(e) => validateEmail(e.target.value)}
                                helperText={ validators.email && "Email address is invalid."}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Company Email: ${email}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid style={{marginBottom: 0}} xs={12} item className={classes.gridElements}>
                        <hr style={{width: '75%'}} />
                        <Typography variant='h6'>Company Address</Typography>
                        <Typography variant='subtitle2' color='primary'>(Billing Address)</Typography>
                    </Grid>
                    <hr style={{width: '75%'}} />
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='street1-input'
                                label='Street 1'
                                required
                                onChange={(ele) => { setStreet1(ele.currentTarget.value) }}
                                value={street1}
                                name='street1'
                                error={validators.street1}
                                onBlur={(e) => validateStreet1(e.target.value)}
                                helperText={ validators.street1 && "Street 1 is invalid."}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Street 1: ${street1}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='street2-input'
                                label='Street 2'
                                onChange={(ele) => { setStreet2(ele.currentTarget.value) }}
                                value={street2}
                                name='street2'
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Street 2: ${street2}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='city-input'
                                label='City'
                                required
                                onChange={(ele) => { setCity(ele.currentTarget.value) }}
                                value={city}
                                name='city'
                                error={validators.city}
                                onBlur={(e) => validateCity(e.target.value)}
                                helperText={ validators.city && "City is invalid."}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`City: ${city}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <StateTextBox
                                label='State'
                                id='state-input'
                                value={state}
                                name='state'
                                onChange={(ele) => { setState(ele.target.value) }}
                                error={validators.state}
                                onBlur={(e) => validateState(e.target.value)}
                                helperText={ validators.state && "State is invalid."}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`State: ${state}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        {edit ?
                            <TextField
                                className={classes.textField}
                                id='zip-input'
                                label='Zip'
                                required
                                onChange={(ele) => { setZip(ele.currentTarget.value) }}
                                value={zip}
                                name='zip'
                                InputProps={{
                                    inputComponent: NumberTextMask
                                }}
                                InputLabelProps={{notched: true, shrink: true}}
                                error={validators.zip}
                                onBlur={(e) => validateZip(e.target.value)}
                                helperText={ validators.zip && "Zip is invalid."}
                            />
                            :
                            <FormLabel className={classes.FormLabel}>
                                {`Zip: ${zip}`}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            type="submit"
                            disabled={isSending}
                        >
                            Save
                        </Button>
                    </Grid>
                    <Grid xs={12} item className={classes.gridElements}>
                        All things
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
  }
  
  export default CustomerDetails;