import React from 'react';
import { makeStyles } from '@mui/styles';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu'
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100vw'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    cursor: 'pointer'
  },
  logoutButton: {
    display: 'inline-flex'
  },
  userNameText: {
    marginTop: '.3em',
    marginRight: '1em'
  }
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();

  let history = useHistory();

  const goBack = () => {
    history.goBack();
  }

  return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Toolbar>
          { history.location.pathname !== '/home' ?
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={goBack}>
              <ArrowBackIcon />
            </IconButton>
            :
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => { history.push('/dashboard') }}>
              <MenuIcon />
            </IconButton>
          }
          <Typography variant="h6" className={classes.title} onClick={() => { history.push('/home'); } }>
            Eagle Foundry Utah
          </Typography>
          {
            !props.isLoggedIn() ?
              <Button color="inherit" variant="outlined" onClick={() => history.push('/sign-in')}>Employee Login</Button>
            :
              <div className={classes.logoutButton}>
                <Typography className={classes.userNameText}></Typography>
                <Button color="inherit" variant="outlined" onClick={() => history.push('/sign-out')}>Logout</Button>
              </div>
          }
          
        </Toolbar>
      </AppBar>
    </div>
  );
}
