import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GetHeaders } from '../utils/utils';
import axios from 'axios';
import { Chart } from "react-google-charts";

const useStyles = makeStyles((theme) => ({
    dashboardContainer: {
        marginTop: '1em',
        padding: '10px'
    },
    gridElements: {
        marginBottom: '.5em'
    },
    paperContainer: {
        padding: '5px'
    }
  }));

  function Dashboard(props) {
    const classes = useStyles();
    const [todaysOrders, setTodaysOrders] = React.useState(0);
    const [lastWeeksOrders, setLastWeeksOrders] = React.useState(0);
    const [ordersThisYear, setOrdersThisYear] = React.useState(0);
    const [totalActiveOrders, setTotalActiveOrders] = React.useState(0);
    const [ordersByStatus, setOrdersByStatus] = React.useState();
    const [lastWeeksShipped, setLastWeekShipped] = React.useState(0);
    const [yesterdaysShipped, setYesterdaysShipped] = React.useState(0);
    const [shippedThisYear, setShippedThisYear] = React.useState(0);
    const [ordersByClosedStatus, setOrdersByClosedStatus] = React.useState();
    const [ordersByAgeAtDeath, setOrdersByAgeAtDeath] = React.useState();

    React.useEffect(() => {
        props.setBackdrop(true);

        axios.get(`${process.env.REACT_APP_API_URI}/reports/dashboard`, GetHeaders())
            .then((response) => {
                setTodaysOrders(response.data.todaysOrders);
                setLastWeeksOrders(response.data.lastWeeksOrders);
                setOrdersThisYear(response.data.ordersThisYear);
                setTotalActiveOrders(response.data.totalActiveOrders);
                setLastWeekShipped(response.data.lastWeeksShipped);
                setYesterdaysShipped(response.data.yesterdaysShipped);
                setShippedThisYear(response.data.shippedThisYear);

                let orderByStatusData = [];
                orderByStatusData.push(["Status", "Quantity"]);
                for (const [key, value] of Object.entries(response.data.ordersByStatus))
                {
                    orderByStatusData.push([key, value]);
                }
                setOrdersByStatus(orderByStatusData);

                let orderByClosedStatusData = [];
                orderByClosedStatusData.push(["Status", "Quantity"]);
                for (const [key, value] of Object.entries(response.data.ordersByClosedStatus))
                {
                    orderByClosedStatusData.push([key, value]);
                }
                setOrdersByClosedStatus(orderByClosedStatusData);

                let ordersByAgeAtDeath = [];
                ordersByAgeAtDeath.push(["Age", "Quantity"]);
                for (const [key, value] of Object.entries(response.data.ordersByAgeAtDeath))
                {
                    ordersByAgeAtDeath.push([key, value]);
                }
                setOrdersByAgeAtDeath(ordersByAgeAtDeath);

            })
            .catch((error) => {
                props.setSnackbar({message: "Error loading dashboard data.", severity: 'error', open: true});
            })
            .finally((tings) => {
                // End the loading screen
                props.setBackdrop(false);
            });

    }, []);

    const options = {
        title: "Active Orders By Status",
        is3D: true
      };

    const optionsClosed = {
        title: "Closed Orders By Status",
        is3D: true
    };

    const optionsAge = {
        title: "Quantity of Deceased by Age"
    };

    return (
        <div className={classes.dashboardContainer}>
            <Paper className={classes.paperContainer}>
                <Grid container>
                    <Grid xs={12} item className={classes.gridElements}>
                        <Typography variant='h4' >Dashboard</Typography>
                        <hr />
                    </Grid>
                    <Grid style={{marginTop: 30}} container xs={12} lg={6}>
                        <Grid xs={12} item className={classes.gridElements}>
                            <Typography variant='h6' >Order Stats</Typography>
                            <hr />
                            <div style={{display: 'inline-flex'}}>
                                <Typography variant='subtitle1' >Daily New Orders:</Typography>
                                <Typography style={{marginLeft: 5}} variant='subtitle1' color='darkred'>{todaysOrders}</Typography>
                            </div>
                        </Grid>
                        <Grid xs={12} item className={classes.gridElements}>
                            <div style={{display: 'inline-flex'}}>
                                <Typography variant='subtitle1' >Orders in the Last Week:</Typography>
                                <Typography style={{marginLeft: 5}} variant='subtitle1' color='darkred'>{lastWeeksOrders}</Typography>
                            </div>
                        </Grid>
                        <Grid xs={12} item className={classes.gridElements}>
                            <div style={{display: 'inline-flex'}}>
                                <Typography variant='subtitle1' >Total Active Orders:</Typography>
                                <Typography style={{marginLeft: 5}} variant='subtitle1' color='darkred'>{totalActiveOrders}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid style={{marginTop: 30}} container xs={12} lg={6}>
                        <Grid xs={12} item className={classes.gridElements}>
                            <Typography variant='h6' >Shipping Stats</Typography>
                            <hr />
                            <div style={{display: 'inline-flex'}}>
                                <Typography variant='subtitle1' >Orders Shipped in last 24 hours:</Typography>
                                <Typography style={{marginLeft: 5}} variant='subtitle1' color='darkred'>{yesterdaysShipped}</Typography>
                            </div>
                        </Grid>
                        <Grid xs={12} item className={classes.gridElements}>
                            <div style={{display: 'inline-flex'}}>
                                <Typography variant='subtitle1' >Orders Shipped in the Last Week:</Typography>
                                <Typography style={{marginLeft: 5}} variant='subtitle1' color='darkred'>{lastWeeksShipped}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid style={{marginTop: 30}} container xs={12} lg={6}>
                        <Grid xs={12} item className={classes.gridElements}>
                            <Typography variant='h6' >Year Stats</Typography>
                            <hr />
                            <div style={{display: 'inline-flex'}}>
                                <Typography variant='subtitle1' >Orders This Year:</Typography>
                                <Typography style={{marginLeft: 5}} variant='subtitle1' color='darkred'>{ordersThisYear}</Typography>
                            </div>
                        </Grid>
                        <Grid xs={12} item className={classes.gridElements}>
                            <div style={{display: 'inline-flex'}}>
                                <Typography variant='subtitle1' >Orders Shipped This Year:</Typography>
                                <Typography style={{marginLeft: 5}} variant='subtitle1' color='darkred'>{shippedThisYear}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid style={{marginTop: 30}} xs={12} item className={classes.gridElements}>
                        <Chart
                            chartType='PieChart'
                            data={ordersByStatus}
                            options={options}
                            width="100%"
                        />
                    </Grid>
                    <Grid style={{marginTop: 30, marginBottom: 150}} xs={12} item className={classes.gridElements}>
                        <Chart
                            chartType='PieChart'
                            data={ordersByClosedStatus}
                            options={optionsClosed}
                            width="100%"
                        />
                    </Grid>
                    <Grid style={{marginTop: 30, marginBottom: 150}} xs={12} item className={classes.gridElements}>
                        <Chart
                            chartType='ColumnChart'
                            data={ordersByAgeAtDeath}
                            options={optionsAge}
                            width="100%"
                        />
                    </Grid>
                </Grid>
            </Paper>
            
        </div>
    );
  }
  
  export default Dashboard;