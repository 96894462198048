import React from 'react';
import { Box, Checkbox, Grid, Paper, Tab, Tabs, TextField, Typography, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import DataTable from '../components/dataTable';
import { GetHeaders } from '../utils/utils';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    dashboardContainer: {
        marginTop: '1em',
        padding: '10px'
    },
    gridElements: {
        marginBottom: '.5em'
    },
    textField: {
        width: '200px'
    },
    paperContainer: {
        padding: '5px'
    },
    tabElement: {
        width: '50%'
    }
  }));

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const statusNames = ["Ordered",
  "Proof",
  "AwaitingCustomerApproval",
  "Setup",
  "Casting",
  "CleanUp" ,
  "Shipped",
  "Cancelled",
  "WaitingForPayment"];

  function Search(props) {
    const classes = useStyles();
    const [searchCriteria, setCriteria] = React.useState(0);
    const [searchOrderValue, setSearchOrderValue] = React.useState('');
    const [searchOrderLegacy, setSearchOrderLegacy] = React.useState(false);
    const [searchCustomerValue, setSearchCustomerValue] = React.useState('');
    const [orderData, setOrderData] = React.useState([]);
    const [orderHeaders, setOrderHeaders] = React.useState([]);
    const [customerData, setCustomerData] = React.useState([]);
    const [customerHeaders, setCustomerHeaders] = React.useState([]);

    React.useEffect(() => {
      props.setBackdrop(true);

      axios.get(`${process.env.REACT_APP_API_URI}/customer/list`, GetHeaders())
          .then((response) => {
              setCustomerData(response.data);
              setCustomerHeaders([
                "id",
                "companyName",
                "contact",
                "phone",
                "fax",
                "email"
            ]);

              axios.get(`${process.env.REACT_APP_API_URI}/order/list`, GetHeaders())
              .then((response) => {
                  response.data.forEach(element => {
                    element.companyName = element.customer.companyName;
                    element.statusName = statusNames[element.status - 1];
                    element.orderDate = new Date(element.orderDate).toLocaleDateString();
                  });
                  setOrderData(response.data);
                  setOrderHeaders([
                    "id",
                    "markerFirstName",
                    "markerMiddleName",
                    "markerLastName",
                    "statusName",
                    "orderDate",
                    "customerId",
                    "companyName"
                ]);

                // End the loading screen
                props.setBackdrop(false);
              })
              .catch((error) => {
                  props.setSnackbar({message: "Error loading order data.", severity: 'error', open: true});
              })
          })
          .catch((error) => {
              props.setSnackbar({message: "Error loading customer data.", severity: 'error', open: true});
          })
          .finally((tings) => {
              
          });

  }, []);

    const handleCriteriaChange = (event, newValue) => {
        setCriteria(newValue);
    };

    const handleLegacyChange = (value) => {
      setSearchOrderLegacy(value);
      props.setBackdrop(true);

      axios.get(`${process.env.REACT_APP_API_URI}/order/list?legacy=${value}`, GetHeaders())
        .then((response) => {
            response.data.forEach(element => {
              element.companyName = element.customer.companyName;
              element.statusName = statusNames[element.status - 1];
              element.orderDate = new Date(element.orderDate).toLocaleDateString();
            });
            setOrderData(response.data);
            setOrderHeaders([
              "id",
              "markerFirstName",
              "markerMiddleName",
              "markerLastName",
              "statusName",
              "orderDate",
              "customerId",
              "companyName"
          ]);
        })
        .catch((error) => {
          props.setSnackbar({message: "Error loading order data.", severity: 'error', open: true});
        })
        .finally((tings) => {
          props.setBackdrop(false);
        });
    };

    return (
        <div className={classes.dashboardContainer}>
            <Paper className={classes.paperContainer}>
                <Typography variant='h4'>Search</Typography>
                <Tabs value={searchCriteria} onChange={handleCriteriaChange}>
                    <Tab className={classes.tabElement} label='Orders' {...a11yProps(0)} /> 
                    <Tab className={classes.tabElement} label='Customers' {...a11yProps(0)} /> 
                </Tabs>
                <TabPanel value={searchCriteria} index={0}>
                    <Grid container spacing={2}>
                        <Grid xs={12} item className={classes.gridElements}>
                            <TextField
                                className={classes.textField}
                                id='searchOrders-input'
                                label='Search'
                                onChange={(ele) => { setSearchOrderValue(ele.currentTarget.value) }}
                                value={searchOrderValue}
                            />
                        </Grid>
                        <Grid xs={12} item className={classes.gridElements}>
                          <FormControlLabel
                              control={
                                  <Checkbox
                                      checked={searchOrderLegacy}
                                      onChange={(ele) => handleLegacyChange(ele.target.checked)}
                                      color='primary'
                                  />
                              }
                              label='Include Legacy'
                          />
                        </Grid>
                        <Grid style={{marginBottom: '1.5em'}} xs={12} item className={classes.gridElements}>
                          <DataTable data={orderData} headers={orderHeaders} type='Order' searchCriteria={searchOrderValue.split(' ')} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={searchCriteria} index={1}>
                    <Grid container spacing={2}>
                        <Grid xs={12} item className={classes.gridElements}>
                            <TextField
                                className={classes.textField}
                                id='searchCustomers-input'
                                label='Search'
                                onChange={(ele) => { setSearchCustomerValue(ele.currentTarget.value) }}
                                value={searchCustomerValue}
                            />
                            <br />
                        </Grid>
                        <Grid style={{marginBottom: '1.5em'}} xs={12} item className={classes.gridElements}>
                        <DataTable data={customerData} headers={customerHeaders} type='Customer' searchCriteria={searchCustomerValue.split(' ')} />
                        </Grid>
                    </Grid>
                </TabPanel>
            </Paper>
        </div>
    );
  }
  
  export default Search;