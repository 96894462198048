import * as React from 'react';
import { Box, Grid, Link, Checkbox, FormControlLabel, TextField, Button, Typography, Avatar } from '@mui/material';
import Container from '@mui/material/Container';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import mobile from 'is-mobile';

import Copyright from '../components/copyright';

export default function SignIn(props) {
  let history = useHistory();
  const search = useLocation().search;

  const [isSending, toggleSending] = React.useState(false);
  const [validators, setValidations] = React.useState({email: false});
  const [redirectUrl, setRedirectUrl] = React.useState();

  React.useEffect(() => {
      const queryParams = new URLSearchParams(search)
  
      if (queryParams.has('redirectUrl')) {
        setRedirectUrl(queryParams.get('redirectUrl'));
        queryParams.delete('redirectUrl');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }, []);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(search)

    if (queryParams.has('redirect')) {

      props.setSnackbar({message: "You must login to access this page.", severity: "info", open: true });
      queryParams.delete('redirect');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValidations({...validators, email: !emailRegex.test(String(email).toLowerCase())});
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validators.email)
    {
        props.setSnackbar({message: "The form is not properly filled out. Please check input for errors and try again.", severity: "error", open: true });
        return;
    }

    props.setBackdrop(true);
    toggleSending(true);
    
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    axios.post(`${process.env.REACT_APP_API_URI}/Authentication/getToken`, {
      email: data.get('email'),
      password: data.get('password'),
      rememberMe: data.get('remember') != null
    })
    .then((response) => {
      // Add interval until redirect to sign-in
      document.cookie = `AuthJWT=Bearer ${response.data.token};expires=${new Date(response.data.expires).toUTCString()};`;

      if (redirectUrl)
      {
        // Snackbar success
        props.setSnackbar({message: "Sign-in successful! Navigating to requested page.", severity: "success", open: true });
        window.location = redirectUrl;
      }
      else
      {
        // Snackbar success
        props.setSnackbar({message: "Sign-in successful! Navigating to Dashboard.", severity: "success", open: true });
        history.push('/dashboard');
      }
    })
    .catch((error) => {
      props.setSnackbar({message: "Error authenticating user.", severity: "error", open: true });
    })
    .finally((tings) =>
    {
        // End the loading screen
        props.setBackdrop(false);

        // Enable Button
        toggleSending(false);
    });
  };

  return (
      
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            height: '100%',
            width: '100vw'
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <Container maxWidth="sm" sx={{ alignItems: 'center', display: 'block'}}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', display: 'inline-flex' }}>
              <img src="/EF_Icon.png" alt="EagleFoundryIcon" width="300%" />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <div style={{display: 'block', marginTop: '1em'}}>
              <TextField
                sx={{ width: mobile() ? "225px" : "260px", marginBottom: '1em'}}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={validators.email}
                onBlur={(e) => validateEmail(e.target.value)}
                helperText={ validators.email && "Email address is invalid."}
              />
              <div style={{display: 'block'}}>
                <TextField
                  sx={{ width: mobile() ? "225px" : "260px", marginBottom: '1em'}}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </div>
              <div style={{display: 'block'}}>
                <FormControlLabel
                  sx={{ width: mobile() ? "225px" : "260px", marginBottom: '1em'}}
                  control={<Checkbox name="remember" value={"remember"} color="primary" />}
                  label="Remember me"
                />
              </div>
              <Button
                sx={{ width: mobile() ? "225px" : "260px", marginBottom: '1em'}}
                type="submit"
                fullWidth
                variant="contained"
                disabled={isSending}
              >
                SIGN IN
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link style={{cursor: 'pointer'}} onClick={() => history.push('/forgot-password')} color='secondary' variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item xs>
                  <Link style={{cursor: 'pointer'}} onClick={() => history.push('/register')} color='secondary' variant="body2">
                    Don't have an account? Sign Up
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 8, mb: 4}} />
            </div>
        </Container>
        </Box>
      
  );
}