import React from 'react';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { visuallyHidden } from '@mui/utils';
import _ from 'lodash';
import {v4 as uuidv4} from 'uuid';

import { isSmallScreen } from '../utils/utils';

const useStyles = makeStyles({
  table: {
    maxWidth: '100%',
  },
  root: {
    maxWidth: isSmallScreen() ? '300px' : '100%',
    padding: '0px',
    maxHeight: isSmallScreen() ? '90%' : '900px'
  }
});

export default function DataTable(props) {
  const classes = useStyles();
  let history = useHistory();
  
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');

  const handleOrderChange = (property) => {
    if (property === orderBy)
    {
      if (order === 'asc')
      {
        setOrder('desc');
      }
      else
      {
        setOrder('asc');
      }
    }
    else
    {
      setOrder('asc');
      setOrderBy(property);
    }
  };

  let rowData = props.searchCriteria === "" || props.searchCriteria === [] ? props.data : props.data.filter(o => props.searchCriteria.some(p => Object.keys(o)
    .some(k => o[k] !== null && o[k] !== undefined ? o[k].toString().toLowerCase().includes(p.toLowerCase()) : null )));

  rowData = _.orderBy(rowData, orderBy, order);

  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table stickyHeader size="small" className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.headers.map((header) => (
                <TableCell key={`header-${header}`}
                  sortDirection={orderBy === header ? order : false}
                >
                  <TableSortLabel active={orderBy === header}
                    direction={orderBy === header ? order : 'asc'}
                    onClick={() => {handleOrderChange(header)}}
                  >
                    {`${header.substring(0, 1).toUpperCase()}${header.substring(1)}`}
                    {orderBy === header ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.map((row) => (
            <TableRow hover key={uuidv4()}>
              <TableCell key={`column-0-${row[props.headers[0]]}`} component="th" scope="row"
                style={{cursor: 'pointer'}}
                onClick={props.type === 'Order' ? () => {history.push(`/order-details/${row[props.headers[0]]}`)} : () => {history.push(`/customer-details/${row[props.headers[0]]}`)}}
              >
                {row[props.headers[0]]}
              </TableCell>
              <TableCell  key={`column-1-${row[props.headers[1]]}`}>{row[props.headers[1]]}</TableCell>
              <TableCell key={`column-2-${row[props.headers[2]]}`}>{row[props.headers[2]]}</TableCell>
              <TableCell key={`column-3-${row[props.headers[3]]}`}>{row[props.headers[3]]}</TableCell>
              <TableCell key={`column-4-${row[props.headers[4]]}`}>{row[props.headers[4]]}</TableCell>
              <TableCell key={`column-5-${row[props.headers[5]]}`}>{row[props.headers[5]]}</TableCell>
              <TableCell key={`column-6-${row[props.headers[6]]}`}
                style={{cursor: props.type === 'Order' ? 'pointer' : 'default'}}
                onClick={props.type === 'Order' ? () => {history.push(`/customer-details/${row[props.headers[6]]}`)} : null}
              >{row[props.headers[6]]}</TableCell>
              <TableCell key={`column-7-${row[props.headers[7]]}`}>{row[props.headers[7]]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}