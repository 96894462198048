import React from 'react';
import { InputLabel, Select, MenuItem, FormControl, IconButton, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    gridElements: {
        marginBottom: '.5em'
    },
    select: {
        width: 200,
    },
    addButton: {
        position: 'inherit'
    },
    formControl: {
        marginLeft: 48
    }
  }));

  function SelectWithAdd(props) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div>
            <FormControl className={classes.formControl}
                inputProps={{notched: true}}
                error={props.error}
                onBlur={props.onBlur}
            >
                <InputLabel id={`${props.label}-id`} shrink={true}>{props.label}</InputLabel>
                <Select
                    className={classes.select}
                    id={props.id}
                    value={props.value}
                    onChange={props.onChange}
                    labelId={`${props.label}-id`}
                    label={props.label}
                    name={props.name}
                >
                    {props.data.map((menuItem) => (
                        <MenuItem key={menuItem.id} value={menuItem.id}>{menuItem.companyName}</MenuItem> 
                ))}
                </Select>
                <FormHelperText>{props.helperText}</FormHelperText>
            </FormControl>
            <IconButton className={classes.addButton} color='secondary' onClick={() => history.push('/new-customer?r=true')}>
                <AddIcon />
            </IconButton>
        </div>
    );
  }
  
  export default SelectWithAdd;