import * as React from 'react';
import { Box, TextField, Button, Typography, Avatar, Grid, Link } from '@mui/material';
import Container from '@mui/material/Container';
import mobile from 'is-mobile';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import Copyright from '../components/copyright';

export default function ForgotPassword(props) {
    let history = useHistory();

    const [isSending, toggleSending] = React.useState(false);
    const [validators, setValidations] = React.useState({email: false});

    const validateEmail = (email) => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setValidations({...validators, email: !emailRegex.test(String(email).toLowerCase())});
      };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (validators.email)
        {
            props.setSnackbar({message: "The form is not properly filled out. Please check input for errors and try again.", severity: "error", open: true });
            return;
        }
        
        props.setBackdrop(true);
        toggleSending(true);

        // eslint-disable-next-line no-console
        axios.post(`${process.env.REACT_APP_API_URI}/Authentication/forgotPassword`, {
            email: data.get('email')
        })
        .then((response) => {
            // Snackbar success
            props.setSnackbar({message: `An email was sent to: ${data.get('email')}`, severity: "success", open: true });
            // Add interval until redirect to sign-in
            history.push('/sign-in')
        })
        .catch((error) => {
            
            props.setSnackbar({message: "We ran into an error attempting to reset password.", severity: "error", open: true });
        })
        .finally((tings) =>
        {
            // End the loading screen
            props.setBackdrop(false);

            // Enable Button
            toggleSending(false);
        });
  };

  return (
    <Container variant='main' maxWidth="sm">
        <Box
            sx={{
                marginTop: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <img src="/EF_Icon.png" alt="EagleFoundryIcon" width="300%" />
            </Avatar>
            <Typography component="h1" variant="h5">
                Forgot Password
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={validators.email}
                    onBlur={(e) => validateEmail(e.target.value)}
                    helperText={ validators.email && "Email address is invalid."}
                />
                <Typography sx={{width: mobile() ? "225px" : "260px", display: 'block', margin: "0 auto", marginTop: '2em'}} variant="body2">An email will be sent to you with a link to create a new password.</Typography>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isSending}
                >
                    Submit
                </Button>
                <Grid sx={{ m: 'auto', textAlign: 'center'}} container>
                    <Grid item xs>
                        <Link style={{cursor: 'pointer'}} onClick={() => history.push('/sign-in')} color='secondary' variant="body2">
                            Click here to go back to Sign in.
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4}} />
    </Container>
  );
}